import React, { useCallback, useContext, useState } from 'react'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { LgcSfContext } from '../../LgcSfContext'

import { Card } from './LgcSfDndStepWrapper'
import LgcSfStep from './LgcSfStep'

export const LgcSfStepsWrapper = ({ showStepContent, previewMode }) => {
  const { signatureFlow, setSignatureFlow } = useContext(LgcSfContext);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    // Make a copy of the item object
    const newItem = { ...signatureFlow };

    // Modify the steps array in the copy
    const [draggedStep] = newItem.steps.splice(dragIndex, 1);
    newItem.steps.splice(hoverIndex, 0, draggedStep);

    // Update the position column for each step
    newItem.steps.forEach((step, index) => {
      step.position = index;
    });

    // Update the state with the modified copy
    setSignatureFlow(newItem);
  }, [signatureFlow, setSignatureFlow]);

  const renderCard = useCallback((step, index) => {
    return (
      <>
        <Card
          key={index}
          index={index}
          id={index}
          text={step.name}
          moveCard={moveCard}
          step={step}
          showStepContent={showStepContent}
          previewMode={previewMode}
        />
      </>
    )
  }, [signatureFlow])

  return (

    <DndProvider backend={HTML5Backend}>
      <div>{signatureFlow.steps && signatureFlow.steps.map((step, i) => renderCard(step, i))}</div>
      <LgcSfStep
        step={{ 'name': "Witness", 'position': -1 }}
        index={-1}
        showStepContent={showStepContent}
        type={"witness"}
        signatureFlow={signatureFlow}
        previewMode={previewMode}
      />
    </DndProvider>
  )
}
