import { Controller } from '@hotwired/stimulus'
import csrf from '../lib/utils/csrf'

// Connects to data-controller="checkbox-toggle"
export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.onChange)
  }

  disconnect() {
    this.element.removeEventListener('change', this.onChange)
  }

  onChange = event => {
    const element = event.target

    const body = {
      [element.name]: element.checked,
    }

    fetch(this.element.action, {
      method: 'PUT', // TODO: use a hidden `_method` input to support other methods
      headers: {
        ...csrf.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  }
}
