import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import LgcEditor from '../../editor/LgcEditor';
import { selectCloningObject } from '../slices/cloneSlice';

export default function LgcResultsStep({modal: LgcModalBase, isModalOpen,onModalOk,onModalCancel,handleInstructions}) {

  const cloningObject = useSelector(selectCloningObject);
  const selectRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const editorRef = useRef(null);
  const [createCollection,setCreateCollection] = useState(false)
  const [options,setOptions] = useState({})


  const Instructions = () => {
    return (
      <>
        <h1 className='item-title'>Results </h1>
      </>
    )
  }

  const handleOptionChange = (option)=>{
    setSelectedOption(option)
    editorRef.current.editor.updateEditor(option.value)
  }
  const handleError = (error) => {
    Flash.error(error)
    // dispatch(performCloneFailure(error));
    // dispatch(enableResultsStep());
    // dispatch(setStep(4));
  }

  const editorCallbacks = (editor, data) => {
    // const options1 = {}
    // const plasmid_id = `${data.plasmid.id}_plasmid`
    // const plasmid_title = data.plasmid.title
    // const plasmid_data = data.plasmid.data

    // options1[plasmid_id] = {title: plasmid_title, data: JSON.parse(plasmid_data)}
    const options1 = []
    options1.push({label: data.plasmid.title ,value: JSON.parse(data.plasmid.data)})
      data.primers.forEach((primer) => {
      options1.push( {label: primer.title, value: JSON.parse(primer.data)})
    })

    setOptions(options1)
    handleOptionChange(options1[0]);

  }
  useEffect(() => {
    handleInstructions(Instructions)
    return () => {
      setOptions({})
    };

  }, [])
  return (
    <>
    {cloningObject && cloningObject.id ?
      <>
        <div className='method'>
          <div className='form-group' data-lg-id= "select_result" style={{'width': '50%'}}>
            <Select
              options={options}
              value={selectedOption}
              onChange={handleOptionChange}
            />
        </div>
      </div>
      <LgcEditor url={`${window.location.origin}/api/v1/molecular_biology_cloning/${cloningObject.id}/result_sequence_data`} editorRef={editorRef} editorCallbacks={editorCallbacks} handleError={handleError}></LgcEditor>
      <LgcModalBase open={isModalOpen} dialogClassName="lgc-results-dialog">
        <div className='lgc-clone-wizard'>
          <header className='lgc-clone-wizard__header'>
            <h3>Attention</h3>
          </header>
          <section className='lgc-clone-wizard__body overflow-y'>
            <p>Do you want to create the assembled sequence and its primers as items in the inventory collections? </p>
            <div className="lg_switch_checkbox inline">
              <input id="create_collection_cbx" type="checkbox" onChange={() => setCreateCollection(!createCollection)}></input>
              <label htmlFor="create_collection_cbx"></label>
            </div>
            <label htmlFor="create_collection_cbx">{createCollection ? "Create inventory items" : "Do not create inventory"}</label>
          </section>
          <footer className='lgc-clone-wizard__footer'>
            <button className="lg-button white" onClick={onModalCancel}  >
              Cancel
            </button>
            <button className="lg-button df" onClick={() => onModalOk(createCollection)} >
              Ok
            </button>
          </footer>
        </div>
      </LgcModalBase>
      </>
    : null
    }
    </>
  )
}
