import { classMap } from '@common/utils';
import React, { FC, HTMLAttributes } from 'react';
import LgcIcon from './LgcIcon';

interface LgcIconButtonProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'children'> {
  lgId?: string;
  icon: string;
  disabled?: boolean;
}

const LgcIconButton: FC<LgcIconButtonProps> = ({ lgId, className, disabled, icon, ...restProps }) => {
  return (
    <button
      {...restProps}
      data-lg-id={lgId}
      disabled={disabled}
      className={classMap({
        'lg-icon-button': true,
        [className ?? '']: true,
      })}
    >
      <LgcIcon name={icon} />
    </button>
  );
};

export default LgcIconButton;
