import React, { FC } from 'react';

import { classMap } from '@common/utils';

interface LgcLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  htmlFor?: string;
  required?: boolean;
}

const LgcLabel: FC<LgcLabelProps> = ({ htmlFor, required, className, children, ...restProps }) => {
  return (
    <label
      className={classMap({
        required: required,
        [className ?? '']: true,
      })}
      htmlFor={htmlFor}
      {...restProps}
    >
      {children}
    </label>
  );
};

export default LgcLabel;
