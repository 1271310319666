import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

import { setDisableButtons, setStep } from "./steps/stepperSlice";
import { enableAssemblyStep } from "./assembleSlice";
import { resetStettingsState } from "./settingsSlice";

const initialState = {
  status: 'pending',
  fragments: [],
  error: null,
  errorDetails: {},
  disabled: true,
  currentFragmentId: null
}

export const fragmentsSlice = createSlice({
  name: 'fragments',
  initialState,
  reducers: {
    fetchFragmentsStart: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    fetchFragmentsSuccess: (state, action) => {
      state.status = 'completed';
      state.fragments = action.payload;
      state.currentFragmentId = state.fragments[0].id;
      state.error = null;
    },
    fetchFragmentsFailure: (state, action) => {
      state.status = 'pending';
      state.error = true;
      state.errorDetails = action.payload;
    },
    disableFragmentsStep: (state) => {
      state.disabled = true;
    },
    enableFragmentsStep: (state) => {
      state.disabled = false;
    },
    setCurrentFragmentId: (state, action) => {
      state.currentFragmentId = action.payload;
    },
    toggleFlip: (state, action) => {
      const fragmentId = action.payload;
      state.fragments = state.fragments.map((fragment) => {
        if (fragment.id == fragmentId){
          fragment = {...fragment, reverse: !fragment.reverse}
        }
        return fragment;

      })
    },
    updateFragmentPartition: (state, action) => {
      const selectionLayer  = action.payload.selectionLayer;
      const id = state.currentFragmentId;
      state.fragments = state.fragments.map(fragment => {
        if (fragment.id === id) {
          if(fragment.entire_fragment){
            selectionLayer.start = -1
            selectionLayer.end = -1
          }
          return {
            ...fragment,
            seq_start_index: selectionLayer.start == -1 ? null : selectionLayer.start,
            seq_end_index: selectionLayer.end == -1 ? null: selectionLayer.end,
          };
        }
        return fragment;
      });
    },
    entireFragmentRadioSet: (state, action) => {
      const fragmentId = state.currentFragmentId //id
      state.fragments = state.fragments.map((fragment) => {
        if (fragment.id == fragmentId){
          fragment = {...fragment,
                         entire_fragment: parseInt(action.payload.value),
                        //  seq_start_index:null,
                        //  seq_end_index: null
          }
        }
        return fragment;

      })
    },
    updateFragmentsSuccess: (state, action) => {
      state.status = 'completed';
      state.fragments = action.payload;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStettingsState, () => initialState);
  }

});

export const {
  fetchFragmentsStart,
  fetchFragmentsSuccess,
  fetchFragmentsFailure,
  disableFragmentsStep,
  enableFragmentsStep,
  setCurrentFragmentId,
  toggleFlip,
  updateFragmentPartition,
  entireFragmentRadioSet,
  updateFragmentsSuccess
} = fragmentsSlice.actions;

export const fetchFragmentsThunk = (payload) => async (dispatch) => {
  const fragmentsUrl = `/api/v1/molecular_biology_cloning/${payload.cloningId}/molecular_biology_fragments`;
  dispatch(fetchFragmentsStart());
  dispatch(setDisableButtons(true));
  try {
    const response = await axios.get(fragmentsUrl);
    dispatch(fetchFragmentsSuccess(response.data));
  } catch (error) {
    dispatch(fetchFragmentsFailure(error.response.data));
    dispatch(setStep(3));
    Flash.error(error.response.data);
  }finally {
    dispatch(setDisableButtons(false));
  }
}

export const updateFragmentsThunk = (payload) => async (dispatch) => {
  const updateFragmentsUrl = `/api/v1/molecular_biology_cloning/${payload.id}/update_molecular_biology_fragments`;
  dispatch(fetchFragmentsStart());
  dispatch(setDisableButtons(true));
  try {
    const response = await axios.put(updateFragmentsUrl, { molecular_biology_fragments: payload.molecular_biology_fragments }, { headers: {
        'Accept': 'application/json',
      },
    });
    dispatch(updateFragmentsSuccess(response.data));
  } catch (error) {
    dispatch(fetchFragmentsFailure(error.response.data));
    dispatch(setStep(3));
    Flash.error(error.response.data);
  }finally {
    dispatch(setDisableButtons(false));
    dispatch(enableAssemblyStep());
    dispatch(setStep(4));
  }
}

export default fragmentsSlice.reducer;

export const selectCurrentFragment = (state) => {
  if (state.fragments.currentFragmentId)
    return state.fragments.fragments.find(fragment => fragment.id == state.fragments.currentFragmentId);
  return null;
}
export const selectCurrentFragmentId = (state) => {
  return state.fragments.currentFragmentId;
}


export const selectFragments = (state) => state.fragments.fragments;
