import React, { FC, InputHTMLAttributes } from 'react';
import { v4 as uuid } from 'uuid';

interface LgcSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  lgId?: string;
  checked: boolean;
}

const LgcSwitch: FC<LgcSwitchProps> = ({ checked, lgId, id = uuid(), ...restProps }) => {
  return (
    <span className="lg_switch_checkbox inline">
      <input data-lg-id={lgId} id={id} type="checkbox" checked={checked} {...restProps} />
      <label htmlFor={id}></label>
    </span>
  );
};

export default LgcSwitch;
