import { motion } from 'framer-motion';
import React, { FC, useEffect } from 'react';

import LgcIconButton from './LgcIconButton';

interface LgcToastProps {
  content: string;
  level: 'default' | 'success' | 'warning' | 'danger' | 'info';
  timeout?: number;
  dismissible?: boolean;
  onDismiss?: () => void;
}

const LgcToast: FC<LgcToastProps> = ({ content, level, timeout, dismissible = true, onDismiss }) => {
  const toastLevel = level || 'default';

  useEffect(() => {
    if (timeout) {
      const timeoutId = setTimeout(() => onDismiss?.(), timeout);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  return (
    <motion.div
      layout
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className={`lgc-toaster__toast lgc-toaster__toast--${toastLevel}`}
      data-lg-id="page_notification"
      onClick={dismissible ? onDismiss : undefined}
    >
      <span dangerouslySetInnerHTML={{ __html: content }} />
      {dismissible && <LgcIconButton lgId='dismiss_button' icon='times' onClick={onDismiss} />}
    </motion.div>
  );
};

export default LgcToast;
