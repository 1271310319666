import React, { useContext, useState } from 'react'
import { LgcSfContext } from '../../LgcSfContext';

import CreatableSelect from 'react-select/creatable';
import { isWitnessStep } from '../../Utils/LgcSfHelperMethods'


const LgcSfRole = ({roleHandler, currentStep, advanced, formMode}) => {

  const [roles, setRoles] = useState(['Review', 'Manager review', 'QA review', 'RA review'])
  const { itemApplied } = useContext(LgcSfContext);
  const { currentStepIndex } = useContext(LgcSfContext);

  const value = () =>{
    const val = currentStep.role_of_signers
    return { value: val, label: val };
  };

  const options = () => {
    return roles.map((option) => ({ value: option, label: option }))
  };

  const handleCreateOption = (newOption) => {
    roleHandler({ value: newOption })
    setRoles([...roles, newOption])
  }
  return (
    <>
      {(advanced && !isWitnessStep(currentStepIndex)) &&
        <div data-lg-id={`signature_flow_form_role_${currentStepIndex}`} className='form-group'>
          <label className='inline-label margin-bottom-add margin-top-add required' htmlFor='roles'>{I18n.t("signature_flow.form.role_of_signers")}</label>
          <CreatableSelect
            onChange={roleHandler}
            value={value()}
            options={options()}
            onCreateOption={handleCreateOption}
            name='roles'
            className='signature-flow-form-role-input w100'
            placeholder='Select or enter a role'
            isDisabled={itemApplied}
          />
        </div>
      }
    </>
  )
}

export default LgcSfRole;
