import moment from 'moment'
import parse from 'html-react-parser'

export const htmlSafe = (data, dataKey, info) => {
    var value = data[dataKey];
    if (dataKey.endsWith("___base64"))
      return parse("<img src='" + data[dataKey] + "'/>" )
    if (dataKey.endsWith("___link")){
      const val = JSON.parse(data[dataKey]);
      return parse("<a href='" + val["url"] + "'/>" + val["name"] + "</a>")
    }
    if (dataKey.toLowerCase().endsWith("ed_at") || dataKey.toLowerCase().endsWith("ed__at") || dataKey.endsWith("edAt") || dataKey.endsWith("___date")) {
      return moment(data[dataKey]).format('MMM DD, YYYY HH:mm')
    }
    // Collections customizations
    if (data["__typename"] == "Sequence"){ // Sequence //
      if (dataKey.endsWith("___extinctionCoefficient")){
        value += " (M<sup>-1</sup> cm<sup>-1</sup> at 280 nm)";
      }
      if (dataKey.endsWith("___molecularWeight")){
        value += " (g/mol)";
      }
      if (dataKey.endsWith("___seq")){
        value = info.sequence_display;
      }
    }

    return parse(value)
}

export const titelize = (key) => {
    return parse(key.replaceAll("___date", "")
              .replaceAll("___base64","")
              .replaceAll("___link", "")
              .replaceAll("___extinctionCoefficient", "")
              .replaceAll("___molecularWeight", "")
              .replaceAll("___seq", "")
              .replace(/\__\d*?\__/g, function(n){ return '&#' + n.slice(2).slice(0, -2) +';' })
              .replaceAll("_", " "));
}