const csrf = {
  get token() {
    return document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
  },

  get headers() {
    const csrfToken = this.token

    if (csrfToken == null) {
      return {}
    }

    return {
      'X-CSRF-Token': csrfToken,
    }
  },
};

export default csrf;
