import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { MethodEnum } from '../../lgcCloningMethod';
import { selectCloningObject } from "../slices/cloneSlice";
import LgcOrientation from "../../LgcOrientation";

const LgcPerformClone = ({handleInstructions}) => {
  const cloningObject = useSelector(selectCloningObject);
  const status = useSelector(state => state.assemble.status);
  const fragments = useSelector((state) => state.fragments.fragments);

  const Instructions = () => {
    return (
      <>
        <h1 className='item-title'>Overview</h1>
      </>
    )
  }

  function humanize(str) {
    let result = str.replace(/_/g, ' '); // replace underscores with spaces
    return result.charAt(0).toUpperCase() + result.slice(1); // capitalize the first letter
  }

  useEffect(()=>{
    handleInstructions(Instructions);
  },[]);

  return (
    <>
      {status != 'loading' ?
          <div>
          <style>
            {`
              /* CSS for the tooltip */
              .tippy-content {
                max-width: 500px;
                max-height: none;
                overflow: auto;
                white-space: normal;
                display: block
              }
            `}
          </style>

          <div>
            <h3>Cloning Setting</h3>
            <table className='width100 table-striped'>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td data-lg-id= {`cloning_name`}>{cloningObject.name}</td>
                </tr>
                <tr>
                  <th>Process (method):</th>
                  <td data-lg-id= {`process`}>{MethodEnum[cloningObject.cloning_method]}</td>
                </tr>
              </tbody>
            </table>

            <br /> {/* Small separation between tables */}

            <h3>Vector</h3>
            <table className='width100 table-striped'>
              <tbody>
                <tr>
                  <th>Base Vector: </th>
                  <td><a data-lg-id= {`base_vector`} href={cloningObject.base_plasmid.url} target="_blank">{cloningObject.base_plasmid.name}</a></td>
                </tr>
                <tr>
                  <th>Linearization Method:</th>
                  <td data-lg-id= {`linearization_method`}>{humanize(cloningObject.linearization_method)}</td>
                </tr>
                <tr>
                  <th>Enzymes:</th>
                  <td>
                    <ul>
                      {cloningObject.restriction_enzyme_names.map((item,index) => (
                        <li data-lg-id= {`enzyme_${index}`} key={index}>
                          {JSON.parse(item).valueForGibson
                          }
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>

            <br /> {/* Small separation between tables */}

            <h3>Fragments</h3>
            <table className='width100 table-striped'>
              <tbody>
                {fragments.map((item, index) => {
                  return (
                    <>
                      <tr key={`${index}-sequence`}>
                        <th>Sequence</th>
                        <td data-lg-id= {`fragment_${item.sequence_id}`} ><a href={item.url} target="_blank">{item.title}</a></td>
                      </tr>
                      <tr key={`${index}-orientation`}>
                        <th>Orientation</th>
                        <td><LgcOrientation reverse={item.reverse} dataId={`orientation_${item.sequence_id}`}></LgcOrientation></td>
                      </tr>
                      <tr key={`${index}-assemble`}>
                        <th>Assemble As</th>
                        <td data-lg-id= {`assemble_as`}>{item.entire_fragment ? I18n.t('molecular_biology_cloning.entire') : I18n.t('molecular_biology_cloning.part')}</td>
                      </tr>
                      {!item.entire_fragment && (
                        <tr key={`${index}-parts`}>
                          <th>Parts of the sequence</th>
                          <td>{item.seq_start_index}..{item.seq_end_index}</td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>

          </div>
        </div>
      : <div className="d-flex flex-column centered" style={{flexGrow: '1'}}>{I18n.t('molecular_biology_cloning.progress')}</div>}
    </>
  )
}
export default LgcPerformClone;
