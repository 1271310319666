import React from "react"

const LgcOrientation = ({reverse,dataId}) => {

  return (
          <div className='' style={{minWidth: '65px' ,flex: '0'}}>
            <p
              data-lg-id= {dataId}
              style={{
                boxShadow: "0px 0px 5px #888888",
                marginLeft: "5px",
                paddingRight: "5px",
                display: "inline-block",
                paddingLeft: "5px"}}>
              {reverse ? "3' → 5'" : "5' → 3'"}
            </p>
          </div>
  )
}
export default LgcOrientation;



