import React, { FC, useEffect, useState } from 'react';

import { LgcButton, LgcModal } from '@common/components';
import { useMutation } from '@common/hooks';
import * as api from './api';

interface AvantorDisconnectModalProps {
  open: boolean;
  onClose: () => void;
  onDisconnect: () => void;
}

const AvantorDisconnectModal: FC<AvantorDisconnectModalProps> = ({ open, onClose, onDisconnect }) => {
  const [error, setError] = useState<string | null>(null);

  const disconnect = useMutation({ mutationFn: api.disconnect, onSuccess: onDisconnect, onError: setError });

  useEffect(() => {
    setError(null);
  }, [open]);

  return (
    <LgcModal
      dismissible
      size="sm"
      title="Terminate Avantor integration"
      open={open}
      error={error}
      onDismiss={onClose}
      lgId="avantor-disconnect-modal"
      onErrorDismiss={() => setError(null)}
      buttons={[
        <LgcButton small key="cancel" className="ms-auto" color="white" onClick={onClose}>
          Cancel
        </LgcButton>,
        <LgcButton
          small
          key="proceed"
          data-lg-id="avantor-disconnect-modal-proceed-button"
          disabled={disconnect.isLoading}
          loading={disconnect.isLoading}
          onClick={disconnect.mutate}
        >
          Proceed
        </LgcButton>,
      ]}
    >
      <label className="mb-4">
        Are you certain you want to terminate the connection between Avantor's IM and your storage and inventory
        systems?
      </label>
      <p>This action will sever the communication link between Avantor's IM and Labguru.</p>
      <p>Considerations to note:</p>
      <ul>
        <li>Order Processing: Orders will no longer be automatically transmitted.</li>
        <li>Inventory Management: Consumption actions and quantity levels will cease to synchronize.</li>
      </ul>
      <p>Proceeding with this action will disrupt your workflow. Are you sure you want to terminate the connection?</p>
    </LgcModal>
  );
};

export default AvantorDisconnectModal;
