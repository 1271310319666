import { createSlice } from "@reduxjs/toolkit";
import { resetStettingsState } from "./settingsSlice";

const initialState = {
  status: 'pending',
  error: null,
  errorDetails: {},
  disabled: true
}

export const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    updateResultsSuccess: (state) => {
      state.status = 'completed';
      state.error = null;
    },
    disableResultsStep: (state) => {
      state.disabled = true;
    },
    enableResultsStep: (state) => {
      state.disabled = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStettingsState, () => initialState);
  }

});

export const {
  updateResultsSuccess,
  disableResultsStep,
  enableResultsStep
} = resultsSlice.actions;


export default resultsSlice.reducer;
