import React from "react";

import { LgcSfContextProvider } from './LgcSignatureFlow/LgcSfContext';
import LgcSfMain from "./LgcSignatureFlow/LgcSfMain";


const LgcSignatureFlow = ({ flowsParams, members, applied_flows_ids }) => {
  return (
    <LgcSfContextProvider>
      <LgcSfMain
        flowsParams={flowsParams}
        members={members}
        applied_flows_ids={applied_flows_ids}
      />
    </LgcSfContextProvider>
  )
};
export default LgcSignatureFlow;
