import * as Sentry from '@sentry/browser';

const currentEnv = window.config.lgEnv;
// currently for testing purposes  we would like to initialize sentry integration only for staging2
//  TODO remove conditions after evaluation
if (currentEnv === 'staging2') {

  Sentry.init({
    dsn: window.config.SentryDSN,
    environment: currentEnv,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [window.location.origin, /^\/api\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
  });

  if (window.currentUser) {
    Sentry.setUser({
      userId: window.currentUser.user_id,
      memberId: window.currentUser.id,
      accountId: window.currentAccount.id,
      organizationId: window.currentAccount.organization_id,
    });
  }
  Sentry.setContext('Metadata', {
    environment: window.config.lgEnv,
    version: window.config.lgVersion,
  });
}
export default Sentry;
