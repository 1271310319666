import React from 'react'
import {htmlSafe, titelize} from '../utils/Utils'

const ShowInnerTable = ({ data }) => { 
  return (
    <table className='styled_table width100'>
      <thead>
        <tr>
          {_.keys(data[0]).map((key, index) => {
            if (key !== 'customFields' && key !== '__typename')
              return (<th key={"innerth" + index}>{titelize(key)}</th>)
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => {
          return (
          <tr key={"innerrow" + index}>
            {_.keys(row).map((key, index) => {
              if (key !== 'customFields' && key !== '__typename')
                if (typeof row[key] === 'object')
                  return (<td key={"innertd" + index}><ShowInnerTable data={row[key]}></ShowInnerTable></td>)
                else
                return(<td key={"innertd" + index}> {htmlSafe(row, key)} </td>)
            })}
          </tr>
          )
        })}        
      </tbody>
    </table>
  )
}

export default ShowInnerTable