import React, { useContext, useState, useEffect } from 'react';
import { LgcSfContext } from './LgcSfContext';

import LgcSfIndex from './LgcSfIndex';
import { getWitnessersOptions, getStepStructure, getSigFlow } from './Utils/LgcSfApiMethods';
import LgcSfFormWrapper from './Form/LgcSfFormWrapper';
import { displayFlashMessage, checkDeleteStatus } from './Utils/LgcSfHelperMethods'
import LgcSfShow from './LgcSfShow';

const LgcSfMain = ({ flowsParams, members, applied_flows_ids }) => {
  const [flows, setFlows] = useState([]);
  const [editFlowData, setEditFlowData] = useState({});
  const [witnessersOptions, setWitnessersOptions] = useState([]);
  const [stepTemplate, setStepTemplate] = useState({});
  const [disableAllInputs, setDisableAllInputs] = useState(false)
  const [membersArr, setMembersArr] = useState(members)

  const { setItemApplied } = useContext(LgcSfContext);
  const { setSignatureFlow } = useContext(LgcSfContext);
  const { setFormMode } = useContext(LgcSfContext);
  const { page, setPage } = useContext(LgcSfContext);

  useEffect(() => {
    setFlows(flowsParams.reverse())

    const fetchWitnessersOptions = async () => {
      try {
        const { data } = await getWitnessersOptions();
        setWitnessersOptions([{ label: 'Members', options: data.members }, { label: 'Teams', options: data.teams }]);
      } catch (error) {
        displayFlashMessage('error', I18n.t("signature_flow.general_error"));
      }
    };

    const fetchStepStructure = async () => {
      try {
        const { data } = await getStepStructure();
        setStepTemplate(data.step_structure[0])
      } catch (error) {
        displayFlashMessage('error', I18n.t("signature_flow.general_error"));
      }
    };

    fetchWitnessersOptions();
    fetchStepStructure();

  }, [flowsParams]);

  const handleNewFlow = (newFlow) => {
    setFlows([newFlow.item, ...flows]);
    setMembersArr([...members, newFlow.owner])
  };

  const deleteFlow = async (flowToDelete) => {
    let flowDeleted = await checkDeleteStatus(flowToDelete);
    if (flowDeleted === true) {
      setFlows((prevFlows) => prevFlows.filter((flow) => flow.id !== flowToDelete.id))
    };
    setPage("index")
  };

  const updateFlow = (updatedFlow) => {
    // Find the index of the flow in the flows array
    const index = flows.findIndex((flow) => flow.id === updatedFlow.id);

    if (index !== -1) {
      // Create a new array with the updated flow
      const updatedFlows = [...flows];
      updatedFlows[index] = updatedFlow;

      // Update the flows state with the new array
      setFlows(updatedFlows);
    }
  };

  const handlePage = (page) => {
    setItemApplied(false);
    setPage(page);
  };


  const handleEditFlow = async (flow) => {
    if (flow.id === 1) {
      setDisableAllInputs(true)
      return;
    }
    const res = await getSigFlow(flow.id);
    setItemApplied(res.data.disable_logic_fields);
    setEditFlowData(res.data);
    setSignatureFlow(res.data.sanitized_flow)
    setFormMode("edit");
    setPage('signatureFlowForm');
  };

  const handleShowFlow = async (flow) => {
    const res = await getSigFlow(flow.id);
    setItemApplied(res.data.disable_logic_fields);
    setSignatureFlow(res.data.sanitized_flow)
    setPage('LgcSfShow');
  };

  return (
    <div className='tab-pane App' id='tabs-signature_process'>
      {page === 'index' && (
        <LgcSfIndex
          flows={flows}
          members={membersArr}
          deleteFlow={deleteFlow}
          handleEditFlow={handleEditFlow}
          handleShowFlow={handleShowFlow}
          handlePage={handlePage}
          applied_flows_ids={applied_flows_ids}
        />
      )}

      {page === 'signatureFlowForm' && (
        <LgcSfFormWrapper
          editFlowData={editFlowData}
          witnessersOptions={witnessersOptions}
          handleNewFlow={handleNewFlow}
          updateFlow={updateFlow}
          handlePage={handlePage}
          stepTemplate={stepTemplate}
        />
      )}

      {page === 'LgcSfShow' && (
        <LgcSfShow
          handleEditFlow={handleEditFlow}
          deleteFlow={deleteFlow}
          handlePage={handlePage}
        />)}
    </div>
  );
};

export default LgcSfMain;
