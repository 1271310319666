import { createSlice } from '@reduxjs/toolkit';

const initialState = { containerId: window.LgFlash.container };

const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    setContainerId: (state, action) => {
      state.containerId = action.payload;
    }
  }
});

export const { setContainerId } = portalSlice.actions;

export default portalSlice.reducer;
