import React, { FC, Fragment, useCallback } from 'react';

import { LgcCheckbox } from '@common/components';
import { AvantorStockroom } from './types';

interface AvantorStockroomTreeProps {
  stockrooms: AvantorStockroom[];
  selectedStockrooms: string[];
  onChange: (selectedStockrooms: string[]) => void;
}

const AvantorStockroomTree: FC<AvantorStockroomTreeProps> = ({ stockrooms, selectedStockrooms, onChange }) => {
  const handleSelect = (id: string) => {
    if (selectedStockrooms.includes(id)) {
      onChange(selectedStockrooms.filter(stockroomId => stockroomId !== id));
    } else {
      onChange([...selectedStockrooms, id]);
    }
  };

  const renderTree = useCallback(
    (nodes: AvantorStockroom[], level = 0) => {
      return nodes.map(node => {
        const isSelected = selectedStockrooms.includes(node.id);

        return (
          <Fragment key={node.id}>
            <LgcCheckbox
              className="mb-2"
              checked={isSelected}
              label={node.stockroomName}
              data-lg-id={`avantor-stockroom-${node.id}`}
              style={{ paddingLeft: `${level * 30}px` }}
              onChange={() => handleSelect(node.id)}
            />
            {node.childStockroom && renderTree(node.childStockroom, level + 1)}
          </Fragment>
        );
      });
    },
    [stockrooms, selectedStockrooms]
  );

  return <div className="d-flex flex-column">{renderTree(stockrooms)}</div>;
};

export default AvantorStockroomTree;
