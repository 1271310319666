import React, { FC, useEffect, useState } from 'react';

import { LgcButton, LgcModal, LgcSkeleton } from '@common/components';
import { useMutation, useQuery } from '@common/hooks';
import AvantorStockroomTree from './AvantorStockroomTree';
import * as api from './api';
import { AvantorStockroom } from './types';

interface AvantorUpdateStockroomsModalProps {
  open: boolean;
  selectedStockrooms: string[];
  onClose: () => void;
  onUpdated: () => void;
}

const AvantorUpdateStockroomsModal: FC<AvantorUpdateStockroomsModalProps> = ({
  open,
  selectedStockrooms: currentStockrooms,
  onClose,
  onUpdated,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [stockrooms, setStockrooms] = useState<AvantorStockroom[]>([]);
  const [selectedStockrooms, setSelectedStockrooms] = useState(currentStockrooms);

  const getStockrooms = useQuery({
    enabled: open,
    queryFn: api.getStockrooms,
    onError: setError,
    onSuccess: setStockrooms,
  });

  const updateStockrooms = useMutation({ mutationFn: api.updateStockrooms, onSuccess: onUpdated, onError: setError });

  const handleUpdateClick = () => {
    setError(null);
    updateStockrooms.mutate(selectedStockrooms);
  };

  useEffect(() => {
    if (!open) {
      setError(null);
      setStockrooms([]);
      setSelectedStockrooms(currentStockrooms);
    }
  }, [open, currentStockrooms]);

  return (
    <LgcModal
      dismissible
      size="sm"
      title="Update Stockrooms"
      lgId="avantor-update-stockrooms-modal"
      open={open}
      error={error}
      onDismiss={onClose}
      onErrorDismiss={() => setError(null)}
      buttons={[
        <LgcButton small key="cancel" className="ms-auto" color="white" onClick={onClose}>
          Cancel
        </LgcButton>,
        <LgcButton
          small
          key="update"
          data-lg-id="avantor-update-stockrooms-modal-update-button"
          loading={updateStockrooms.isLoading}
          disabled={
            getStockrooms.isError || getStockrooms.isLoading || updateStockrooms.isLoading || !selectedStockrooms.length
          }
          onClick={handleUpdateClick}
        >
          Update
        </LgcButton>,
      ]}
    >
      <label className="mb-2">Choose stockrooms to sync:</label>

      {getStockrooms.isLoading && (
        <>
          <LgcSkeleton height={20} width={100} />
          <LgcSkeleton className="my-2 ms-6" height={20} width={100} />
          <LgcSkeleton className="mb-2 ms-6" height={20} width={120} />
        </>
      )}

      {getStockrooms.isSuccess && (
        stockrooms.length ? (
          <AvantorStockroomTree
            stockrooms={stockrooms}
            selectedStockrooms={selectedStockrooms}
            onChange={setSelectedStockrooms}
          />
        ) : (
          <p className="text-muted">No stockrooms found</p>
        )
      )}
    </LgcModal>
  );
};

export default AvantorUpdateStockroomsModal;
