import { Controller } from "@hotwired/stimulus";
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

// Connects to data-controller="lg-calendar"
export default class extends Controller {
  static values = {
    opts: Object
  }

  connect() {
    window.FullCalendar = { Calendar, dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin }
    window.createLgCalendar();
    window.LgCalendar.init(this.optsValue);
  }

  disconnect() {
    window.LgCalendar.cleanup();
    window.destroyLgCalendar();
  }
}
