import { useCallback, useEffect, useState } from 'react';
import { AsyncFunction, AsyncState } from './types';

type UseQueryState<T extends AsyncFunction> = AsyncState<T> & { refetch: () => void };

interface UseQueryOptions<T extends AsyncFunction> {
  queryFn: T;
  enabled?: boolean;
  refetchInterval?: number;
  onSuccess?: (data: Awaited<ReturnType<T>>) => void;
  onError?: (error: string) => void;
}

export const useQuery = <T extends AsyncFunction>({
  queryFn,
  enabled = true,
  onError,
  onSuccess,
  refetchInterval,
}: UseQueryOptions<T>): UseQueryState<T> => {
  const [data, setData] = useState<null | Awaited<ReturnType<T>>>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchData = useCallback(async () => {
    setError(null);
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const data = await queryFn();
      setData(data);
      onSuccess?.(data);
      setIsSuccess(true);
    } catch (error: any) {
      const message = error.message ?? 'An error occurred';

      setError(message);
      onError?.(message);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [queryFn]);

  useEffect(() => {
    if (!enabled) return;

    fetchData();

    if (refetchInterval) {
      const interval = setInterval(fetchData, refetchInterval);
      return () => clearInterval(interval);
    }
  }, [enabled, refetchInterval]);

  return { data, error, refetch: fetchData, isLoading, isSuccess, isError } as UseQueryState<T>;
};
