import axios from 'axios';

const BASE = '/api/v1/';
const SIGN_FLOW = `${BASE}signature_flows`;
const TOKEN = lgFrontendToken();

const getSigFlow = async (id) => {
  const res = await axios.get(`${SIGN_FLOW}/${id}`, {item: {}, token: TOKEN});
  return res;
};

// const getNewFlowData = async (data) => {
const getNewSigFlow = async ({advanced}) => {
  const res = await axios.get(`${SIGN_FLOW}/new`, {params: {advanced: advanced}});
  return res;
};

const getStepStructure = async () => {
  const res = await axios.get(`${SIGN_FLOW}/get_step_structure`);
  return res;
};

const createSigFlow = async (params) => {
  const res = await axios.post(`${SIGN_FLOW}`, {item: params, token: TOKEN});
  return res;
}

const isCanDeleteSigFlow = async (id) => {
  const res = await axios.get(`${SIGN_FLOW}/${id}/can_delete_item`, {item: {}, token: TOKEN});
  return res;
};

const updateSigFlow = async (item) =>{
  const res = await axios.patch(`${SIGN_FLOW}/${item.id}`, {item: item, token: TOKEN});
  return res;
};

const deleteSigFlow = async (id) => {
  const res = await axios.delete(`${SIGN_FLOW}/${id}`, {item: {}, token: lgFrontendToken() })
  return res;
};

const getWitnessersOptions = async () => {
  const res = await axios.get(`${SIGN_FLOW}/get_witnessers_options`, {item: {}, token: lgFrontendToken() })
  return res;
};


export {
getSigFlow,
getNewSigFlow,
getStepStructure,
isCanDeleteSigFlow,
deleteSigFlow,
updateSigFlow,
createSigFlow,
getWitnessersOptions
};
