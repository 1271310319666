import { createSlice } from "@reduxjs/toolkit";
import { setStep } from "./steps/stepperSlice";
import { enableFragmentsStep } from "./fragmentsSlice";
import { updateCloneThunk } from "./cloneSlice";
import { resetStettingsState } from "./settingsSlice";

const initialState = {
  status: 'pending',
  caretPosition: null,
  enzymes:[],
  error: null,
  errorDetails: {},
  disabled: true
}

export const vectorSlice = createSlice({
  name: 'vector',
  initialState,
  reducers: {
    updateVectorStart: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    updateVectorSuccess: (state, action) => {
      state.status = 'completed';
      state.error = null;
    },
    updateVectorFailure: (state, action) => {
      state.status = 'pending';
      state.error = true;
      state.errorDetails = action.payload;
    },
    updateEnzymes:(state,action) => {
      state.enzymes = action.payload;
    },
    disableVectorStep: (state) => {
      state.disabled = true
    },
    enableVectorStep: (state) => {
      state.disabled = false
    },
    updateCaretPosition: (state,action) => {
      state.caretPosition = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStettingsState, () => initialState);
  }

});

export const {
  updateVectorStart,
  updateVectorSuccess,
  updateVectorFailure,
  disableVectorStep,
  enableVectorStep,
  updateCaretPosition,
  updateEnzymes
} = vectorSlice.actions;

export const updateVectorThunk = (payload) => async (dispatch) => {
  dispatch(updateVectorStart());
  dispatch(updateCloneThunk(payload, callback(dispatch)));

};
const callback = (dispatch) => (data) => {
  dispatch(updateVectorSuccess(data));
  dispatch(enableFragmentsStep());
  dispatch(setStep(3));

}

export default vectorSlice.reducer;
