import React, { useEffect } from 'react';

import LgcStepperStep from './LgcStepperStep';

export default function LgcStepper({steps, onSetStep, currentStepId}) {

  const statusModifierMapping = {
    active: "--active",
    completed: "--completed",
    loading: "--loading",
    skipped: "--inactive-completed",
    pending: ""
  }

  const stepNodes = steps.map((step, i) => {
    return(
      <LgcStepperStep
        key={i}
        id={step.id}
        modifier={statusModifierMapping[step.status]}
        title={step.title}
        disabled={step.disabled}
        onClick={onSetStep}
        currentStepId={currentStepId}
      />
    )
  });

  return (
    <div className="lgc-clone-wizard__stepper">
      <ul className='lgc-progress-steps'>
        {stepNodes}
      </ul>
    </div>
  )
}
