import { LgcBadge, LgcIcon, LgcIconButton, LgcSkeleton, LgcSwitch } from '@common/components';
import React, { FC } from 'react';
import { Flag } from './types';

interface FlagsTableProps {
  flags: Flag[];
  loading: boolean;
  onEdit: (flag: Flag) => void;
  onToggle: (flag: Flag) => void;
  onDelete: (flag: Flag) => void;
}

const skeletons = [60, 180, 500, 100, 60, 60];

const FlagsTable: FC<FlagsTableProps> = ({ flags, loading, onEdit, onDelete, onToggle }) => {
  return (
    <table className="table table-clean table-cell-align-middle">
      <thead>
        <tr>
          <th style={{ width: 60 }}>ID</th>
          <th style={{ width: 220 }}>Flag</th>
          <th>Description</th>
          <th style={{ width: 80 }}>Active</th>
          <th style={{ width: 80 }}></th>
          <th style={{ width: 80 }}></th>
        </tr>
      </thead>

      <tbody>
        {loading && (
          [...Array(2).keys()].map(i => (
            <tr key={i}>
              {skeletons.map((width, i) => (
                <td key={i}>
                  <LgcSkeleton width={width} height={20} />
                </td>
              ))}
            </tr>
          ))
        )}

        {!loading && flags.map(flag => (
          <tr key={flag.id}>
            <td>{flag.id}</td>
            <td>
              <LgcBadge flag lgId='flag-badge' color={flag.color}>
                <LgcIcon className="me-2" name={flag.icon} />
                {flag.title}
              </LgcBadge>
            </td>
            <td>{flag.description}</td>
            <td>
              <LgcSwitch lgId="flag-active-switch" checked={flag.active} onChange={() => onToggle(flag)} />
            </td>
            <td className='text-center'>
              <LgcIconButton lgId="flag-edit-button" icon="edit" disabled={flag.default} onClick={() => onEdit(flag)} />
            </td>
            <td className='text-center'>
              <LgcIconButton lgId="flag-delete-button" icon="trash-can" disabled={flag.default} onClick={() => onDelete(flag)} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FlagsTable;
