import { LgcSelectOption } from '@common/components';
import { kebabToSentence } from '@common/utils';

const flagColorOptions = [
  'green-light',
  'green',
  'green-dark',
  'red-light',
  'red',
  'red-dark',
  'blue-light',
  'blue',
  'blue-dark',
  'orange-light',
  'orange',
  'orange-dark',
  'grey-light',
  'grey',
  'grey-dark',
];

const flagIconOptions = [
  'star',
  'bolt',
  'thumbs-up',
  'thumbs-down',
  'question',
  'exclamation',
  'bell',
  'bell-slash',
  'fire',
  'sun',
];

export const colorSelectOptions: LgcSelectOption[] = flagColorOptions.map(color => {
  const text = kebabToSentence(color);
  return { text, value: color, html: `<div class="color-swatch color-swatch--${color}"></div> ${text}` };
});

export const iconSelectOptions: LgcSelectOption[] = flagIconOptions.map(icon => {
  const text = kebabToSentence(icon);
  return { text, value: icon, html: `<i class="fa fa-${icon}"></i> ${text}` };
});
