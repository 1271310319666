import React from 'react';

import LgcStepperStep from './LgcStepperStep';

export default function LgcStepper({steps, onSetStep, currentStepId, all_members}) {

  const stepNodes = steps.map((step, i) => {
    return(
      <LgcStepperStep
        key={i}
        id={step.id}
        modifier={step.completedCount === step.itemCount ? "--completed" : "--loading"}
        title={step.role}
        disabled={step.disabled}
        onClick={onSetStep}
        currentStepId={currentStepId}
        all_members={all_members}
        step={step}
      />
    )
  });

  return (
    <div className="lgc-clone-wizard__stepper" style={{width: "100%"}}>
      <ul className='lgc-progress-steps'>
        {stepNodes}
      </ul>
    </div>
  )
}
