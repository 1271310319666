import { Flag, HttpMethod } from './types';

const BASE_URL = '/api/v1/flags';

const getToken = (): string => {
  return window.cookie.getAttr(`front_end_token_${window.currentAccount.id}`);
};

const fetchApi = async <T = undefined>(url: string, method: HttpMethod, body?: object): Promise<T> => {
  const params = new URLSearchParams({ token: getToken() });
  const response = await fetch(`${url}?${params.toString()}`, {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: body ? JSON.stringify({ item: { ...body } }) : undefined,
  });

  const json = await response.json();

  if (!response.ok) {
    throw new Error(json?.error_message ?? 'An error occurred');
  }
  return json;
};

export const getFlags = async (): Promise<Flag[]> => {
  return fetchApi<Flag[]>(BASE_URL, 'GET');
};

export const createFlag = async (flag: Partial<Flag>): Promise<Flag> => {
  return fetchApi<Flag>(BASE_URL, 'POST', flag);
};

export const updateFlag = async (flag: Partial<Flag>): Promise<Flag> => {
  return fetchApi<Flag>(`${BASE_URL}/${flag.id}`, 'PUT', flag);
};

export const toggleFlag = async (flag: Flag): Promise<Flag> => {
  return fetchApi<Flag>(`${BASE_URL}/${flag.id}`, 'PUT', { active: !flag.active });
};

export const deleteFlag = async (flag: Flag): Promise<Flag> => {
  return fetchApi<Flag>(`${BASE_URL}/${flag.id}`, 'DELETE');
};
