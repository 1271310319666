import React, { FC, TextareaHTMLAttributes } from 'react';

import { classMap } from '@common/utils';
import LgcLabel from './LgcLabel';

interface LgcFormFieldProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

const LgcFormField: FC<LgcFormFieldProps> = ({ className, label, name, rows = 4, placeholder, ...restProps }) => {
  return (
    <div className="form-group">
      {label && (
        <LgcLabel htmlFor={name} required={restProps.required}>
          {label}
        </LgcLabel>
      )}
      <textarea
        className={classMap({
          'form-control': true,
          [className ?? '']: true,
        })}
        rows={4}
        id={name}
        name={name}
        placeholder={placeholder ?? `${label}...`}
        {...restProps}
      />
    </div>
  );
};

export default LgcFormField;
