import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';

import { LgcAutoHeightContainer, LgcButton, LgcModal, LgcSelect, LgcSelectOption } from '@common/components';
import { useMutation } from '@common/hooks';
import AvantorCredentialsForm from './AvantorCredentialsForm';
import AvantorStockroomTree from './AvantorStockroomTree';
import * as api from './api';
import { AvantorSettings, AvantorStockroom } from './types';

interface AvantorConnectModalProps {
  open: boolean;
  onClose: () => void;
  onConnect: () => void;
}

const defaultSettings: AvantorSettings = {
  email: '',
  xapikey: '',
  password: '',
  server_url: '',
  org_id: '',
  org_name: '',
  stockroom_ids: [],
};

const variants = {
  animate: { x: 0, opacity: 1 },
  initial: (direction: number) => {
    return { opacity: 0, x: direction > 0 ? 100 : -100 };
  },
  exit: (direction: number) => {
    return { opacity: 0, x: direction < 0 ? 100 : -100 };
  },
};

const AvantorConnectModal: FC<AvantorConnectModalProps> = ({ open, onClose, onConnect }) => {
  const [[page, direction], setPage] = useState([1, 0]);
  const [error, setError] = useState<string | null>(null);
  const [settings, setSettings] = useState(defaultSettings);
  const [stockrooms, setStockrooms] = useState<AvantorStockroom[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<LgcSelectOption[]>([]);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const fetchOrganizations = useMutation({
    mutationFn: api.fetchOrganizations,
    onError: setError,
    onSuccess: ({ imeorganizationBaseDTO }) => {
      setOrganizationOptions(imeorganizationBaseDTO.map(dto => ({ value: dto.id, text: dto.orgName })) ?? []);
      paginate(1);
    },
  });

  const fetchStockrooms = useMutation({
    mutationFn: api.fetchStockrooms,
    onError: setError,
    onSuccess: stockrooms => {
      setStockrooms(stockrooms);
      paginate(1);
    },
  });

  const connect = useMutation({ mutationFn: api.connect, onSuccess: onConnect, onError: setError });

  const isLoading = fetchOrganizations.isLoading || fetchStockrooms.isLoading || connect.isLoading;

  useEffect(() => {
    if (!open) {
      setPage([1, 0]);
      setError(null);
      setStockrooms([]);
      setOrganizationOptions([]);
      setSettings(defaultSettings);
    }
  }, [open]);

  const updateSettings = (update: Partial<AvantorSettings>) => setSettings(s => ({ ...s, ...update }));

  const isNextDisabled = () => {
    if (isLoading) return true;

    if (page === 1 && (!settings.email || !settings.password || !settings.server_url || !settings.xapikey)) return true;

    if (page === 2 && (!settings.org_id || !settings.org_name)) return true;

    if (page === 3 && !settings.stockroom_ids.length) return true;

    return false;
  };

  const handleNextClick = () => {
    setError(null);

    if (page === 1) {
      fetchOrganizations.mutate(settings);
    } else if (page === 2) {
      fetchStockrooms.mutate(settings);
    } else {
      connect.mutate(settings);
    }
  };

  const handleCancelClick = () => {
    if (page === 1) return onClose();

    setError(null);

    if (page === 2) updateSettings({ org_id: '', org_name: '' });

    if (page === 3) updateSettings({ stockroom_ids: [] });

    paginate(-1);
  };

  return (
    <LgcModal
      size="sm"
      open={open}
      error={error}
      onDismiss={onClose}
      contentClassName="p-0"
      title="Connect to Avantor IM"
      lgId="avantor-connect-modal"
      onErrorDismiss={() => setError(null)}
      buttons={[
        <LgcButton small key="cancel" className="ms-auto" color="white" onClick={handleCancelClick}>
          {page === 1 ? 'Cancel' : 'Back'}
        </LgcButton>,
        <LgcButton
          small
          key="next"
          data-lg-id="avantor-connect-modal-next-button"
          onClick={handleNextClick}
          loading={isLoading}
          disabled={isNextDisabled()}
        >
          {page !== 3 ? 'Next' : 'Connect'}
        </LgcButton>,
      ]}
    >
      <LgcAutoHeightContainer>
        <AnimatePresence mode="wait" initial={false} custom={direction}>
          <motion.div
            key={page}
            className="p-4"
            variants={variants}
            custom={direction}
            animate="animate"
            initial="initial"
            exit="exit"
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            {page === 1 ? (
              <AvantorCredentialsForm disabled={isLoading} credentials={settings} onChange={updateSettings} />
            ) : page === 2 ? (
              <LgcSelect
                name="org_id"
                label="Organization ID"
                disabled={isLoading}
                lgId="avantor-organization-select"
                placeholder="Select organization..."
                options={organizationOptions}
                value={settings.org_id}
                onChange={value => {
                  updateSettings({
                    org_id: value,
                    org_name: organizationOptions.find(o => o.value === value)!.text,
                  });
                }}
              />
            ) : (
              <>
                <label className="mb-2">Choose stockrooms to sync:</label>
                <AvantorStockroomTree
                  stockrooms={stockrooms}
                  selectedStockrooms={settings.stockroom_ids}
                  onChange={stockroom_ids => updateSettings({ stockroom_ids })}
                />
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </LgcAutoHeightContainer>
    </LgcModal>
  );
};
export default AvantorConnectModal;
