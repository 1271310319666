import React, { FC, InputHTMLAttributes } from 'react';
import { v4 as uuid } from 'uuid';

interface LgcCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  checked: boolean;
}

const LgcCheckbox: FC<LgcCheckboxProps> = ({ label, checked, id = uuid(), style, className, ...restProps }) => {
  return (
    <div style={style} className={`lgc-checkbox ${className ?? ''}`}>
      <input id={id} type="checkbox" checked={checked} {...restProps} />
      <label htmlFor={id}>
        <span></span>
        {label}
      </label>
    </div>
  );
};

export default LgcCheckbox;
