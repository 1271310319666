import { MessageEvent } from 'pubnub';
import { useEffect } from 'react';

import { usePubNub } from '@common/contexts';

interface PubNubMessage {
  source: PubNubMessageSource;
}

interface UsePubNubChannelOptions<T extends PubNubMessage> {
  channel: keyof typeof PUBNUB_CHANNELS;
  source: PubNubMessageSource;
  onMessage?: (message: T) => void;
}

export type PubNubMessageSource = 'bg_job' | 'avantor_status' | 'measurement_weight' | 'workflow';

const PUBNUB_CHANNELS = {
  public: window.config.webNotificationsPublicChannel,
  member: window.config.webNotificationsMemberChannel,
};

export const usePubNubChannel = <T extends PubNubMessage>({
  channel,
  source,
  onMessage,
}: UsePubNubChannelOptions<T>): void => {
  const { pubnub } = usePubNub();

  useEffect(() => {
    if (!pubnub) return;

    const handleMessage = (event: MessageEvent) => {
      if (event.message.source !== source) return;
      onMessage?.(event.message);
    };

    pubnub.addListener({ message: handleMessage });
    pubnub.subscribe({ channels: [PUBNUB_CHANNELS[channel]] });

    return () => {
      pubnub.unsubscribe({ channels: [PUBNUB_CHANNELS[channel]] });
      pubnub.removeListener({ message: handleMessage });
    };
  }, [channel, source, onMessage, pubnub]);
};
