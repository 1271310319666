import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LgcToast } from '@common/components';
import { dismiss } from './slices/toastsSlice';

export default function LgcToasterQ() {
  const toasts = useSelector(state => state.toasts);
  const dispatch = useDispatch();

  return (
    <div className="lgc-toaster__queue">
      <AnimatePresence>
        {toasts.map(item => (
          <LgcToast {...item} key={item.id} onDismiss={() => dispatch(dismiss(item.id))} />
        ))}
      </AnimatePresence>
    </div>
  );
}
