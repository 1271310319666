// LGDOC utility wrapper that allows to control where a react component is rendered
// requires your redux store to implement the portal slice
// how to use: see LgcToaster and its store for reference

import React from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

export default function LgcPortal({ children }) {
  const containerId = useSelector((state) => state.portal.containerId);
  const portalTarget = document.getElementById(containerId);

  return(
    <>
      {portalTarget ? createPortal(children, portalTarget) : null}
    </>
  )
}
