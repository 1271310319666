import React, { useContext } from "react";

import { LgcSfContext } from "./LgcSfContext";
import { LgcSfStepsWrapper } from "./Form/StepsWrapper/LgcSfStepsWrapper";

const LgcSfShow = ({ handleEditFlow, deleteFlow, handlePage }) => {

  const { signatureFlow } = useContext(LgcSfContext);
  const { itemApplied } = useContext(LgcSfContext);

  return (
    <>
     <div className='flex'>
        <button data-lg-id='signatureFlow-back-button' className='lg-back-button'>
      <i className='fa fa-chevron-circle-left ' onClick={() => handlePage('index')}></i>
      </button>
      <h1>{signatureFlow.name}</h1></div>

      {!itemApplied &&
        <div className='lg-item-info-menu'>
          <button className='menu-item-button' onClick={() => handleEditFlow(signatureFlow)}>
          <i className='fa fa-edit'></i> {I18n.t("signature_flow.edit")}
          </button>
          <button className='menu-item-button' onClick={() => deleteFlow(signatureFlow)}>
            <i className='fa-solid fa-trash-can'></i> {I18n.t("signature_flow.delete")}
          </button>
        </div>
      }
      <div className='row padding-top-add'>
        <div className='col-md-4'>
          < LgcSfStepsWrapper
            showStepContent={true}
            previewMode={true}
          />
        </div>
      </div>
    </>
  )
};

export default LgcSfShow;
