import React, { FC, HTMLAttributes } from 'react';

import { classMap } from '@common/utils';

interface LgcIconProps extends HTMLAttributes<HTMLElement> {
  name: string;
}

const LgcIcon: FC<LgcIconProps> = ({ className, name, ...restProps }) => {
  return (
    <i
      className={classMap({
        fa: true,
        [`fa-${name}`]: true,
        [className ?? '']: true,
      })}
      {...restProps}
    />
  );
};

export default LgcIcon;
