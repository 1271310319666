export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface Flag {
  id: number,
  title: string;
  description: string;
  color: string;
  icon: string;
  active: boolean,
  default: boolean,
  deleted_at: string | null
}
