import React from 'react';


export default function LgcInteractivePanel({Stepper, steps, onSetStep, currentStepId, Instructions}){

return (
        <>
          <Stepper steps={steps} onSetStep={onSetStep} currentStepId={currentStepId}/>
          <div data-lg-id='lgc_instructions' className='lgc-instructions'>
            {Instructions && Instructions}
          </div>
        </>
        )
  }

