import React, { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { selectCloningObject } from '../slices/cloneSlice';
import { fetchFragmentsThunk, selectCurrentFragment, setCurrentFragmentId, updateFragmentsSuccess } from '../slices/fragmentsSlice';
import LgcFragmentShow from "./LgcFragments/LgcFragmentShow";
import LgcOrientation from "../../LgcOrientation";

const LgcFragments = (props) => {
  const dispatch = useDispatch();
  const fragments = useSelector((state) => state.fragments.fragments);
  const currentFragment = useSelector(selectCurrentFragment);
  const cloningObject = useSelector(selectCloningObject);
  const status = useSelector(state => state.fragments.status);

  const Instructions = () => {
    return (
      <h1 className='item-title'>{I18n.t('molecular_biology_cloning.molecular_biology_fragments')} </h1>
    )
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = fragments.map(item => ({ ...item }));
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);
    reorderedItems.forEach((item, index) => {
      item.position = index;
    });
    dispatch(updateFragmentsSuccess(reorderedItems));
  }

  useEffect(() => {
    props.handleInstructions(Instructions)
    if(!currentFragment){
      if(!fragments.length){
        const payload = {
          cloningId: cloningObject.id,
        };
        dispatch(fetchFragmentsThunk(payload));
      }else{
        dispatch(setCurrentFragmentId(fragments[0].id))
      }
    }

  }, []);

  const currentFragmentHandler = (fragment_id) => {
    dispatch(setCurrentFragmentId(fragment_id));
  };

  return (
    <>
      {status != 'loading' ?
        <div className='lgc-clone-wizard-fragments'>
          <div className='lgc-clone-wizard-fragments__sidebar'>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="reorderable-list">
                {({innerRef, droppableProps, placeholder}) => (
                  <ul
                    ref={innerRef}
                    {...droppableProps}
                    className='lgc-clone-wizard-fragments__list'>
                      {fragments.map((item,index) => (
                        <Draggable key={item.id} draggableId={item.id.toString()} index={index} isDragDisabled={fragments.length < 2}>
                          {({innerRef, draggableProps, dragHandleProps}) => (
                            <li
                              ref={innerRef}
                              {...draggableProps}
                              {...dragHandleProps}
                              className={`lgc-clone-wizard-fragments__list-item ${currentFragment && currentFragment.id === item.id ? 'lgc-clone-wizard-fragments__list-item--active' : ''}`}
                              key={item.id} onClick={() => currentFragmentHandler(item.id)}>
                              <p data-lg-id= {`sequence_name_${item.id}`}>Sequence Name: {item.title}</p>
                              {(!item.entire_fragment && (item.seq_start_index == null || item.seq_end_index == null)) && <i className="fa fa-exclamation-triangle" data-tip='invalid input'></i>}
                              <LgcOrientation reverse={item.reverse} dataId={`orientation_${item.id}`}></LgcOrientation>
                            </li>
                          )}
                        </Draggable>
                      ))}
                    {placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {currentFragment?
            <div className='lgc-clone-wizard-fragments__main'>
              <LgcFragmentShow currentFragment={currentFragment}/>
              <div className='lgc-clone-wizard-fragment-dna-viewer'></div>
            </div>
        : null}
        </div>
        : <div className="d-flex flex-column centered" style={{flexGrow: '1'}}>Loading...</div>}
      </>
  )
}

export default LgcFragments;
