import { useState } from 'react';
import { AsyncFunction, AsyncState } from './types';

export type UseMutationState<T extends AsyncFunction> = AsyncState<T> & { mutate: (...args: Parameters<T>) => void; };

interface UseMutationOptions<T extends AsyncFunction> {
  mutationFn: T;
  onError?: (error: string) => void;
  onSuccess?: (data: Awaited<ReturnType<T>>) => void;
}

export const useMutation = <T extends AsyncFunction>(options: UseMutationOptions<T>): UseMutationState<T> => {
  const [data, setData] = useState<Awaited<ReturnType<T>> | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const mutate = async (...args: Parameters<T>) => {
    setError(null);
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const data = await options.mutationFn(...args);
      setData(data);
      setIsSuccess(true);
      options.onSuccess?.(data);
    } catch (error: any) {
      const message = error.message ?? 'An error occurred';

      setError(message);
      setIsError(true);
      options.onError?.(message);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, isSuccess, isError, mutate } as UseMutationState<T>;
};
