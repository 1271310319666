import React, { FC, useCallback, useState } from 'react';

import { LgcSwitch } from '@common/components';
import { usePubNubChannel, useQuery } from '@common/hooks';
import AvantorConnectModal from './AvantorConnectModal';
import AvantorDisconnectModal from './AvantorDisconnectModal';
import AvantorUpdateStockroomsModal from './AvantorUpdateStockroomsModal';
import { getSettings } from './api';
import { AvantorSettings, AvantorSyncStatus } from './types';

interface AvantorIntegrationSettingsProps {
  status: AvantorSyncStatus;
}

interface AvantorStatusMessage {
  source: 'avantor_status';
  status: AvantorSyncStatus;
}

type AvantorIntegrationModal = 'connect' | 'update' | 'disconnect' | null;

const AvantorIntegrationSettings: FC<AvantorIntegrationSettingsProps> = ({ status: currentStatus }) => {
  const [activeModal, setActiveModal] = useState<AvantorIntegrationModal>(null);
  const [settings, setSettings] = useState<AvantorSettings | null>(null);
  const [status, setStatus] = useState<AvantorSyncStatus>(currentStatus);

  const connected = status !== AvantorSyncStatus.Disconnected;
  const syncing = connected && status !== AvantorSyncStatus.Synced;

  const handleStatusMessage = useCallback(({ status }: AvantorStatusMessage) => {
    setStatus(status);

    if (status === AvantorSyncStatus.Disconnected) {
      setSettings(null);
    }
  }, []);

  usePubNubChannel<AvantorStatusMessage>({
    channel: 'member',
    source: 'avantor_status',
    onMessage: handleStatusMessage,
  });

  useQuery({ queryFn: getSettings, enabled: status === AvantorSyncStatus.Synced, onSuccess: setSettings });

  const handleModalSubmit = (status: AvantorSyncStatus) => {
    setActiveModal(null);
    setStatus(status);
  };

  return (
    <>
      <td>
        <label>Avantor integration</label>
        {connected && (
          <small className="block">
            <a
              data-lg-id="avantor-update-stockrooms-button"
              className={syncing ? 'disabled' : ''}
              onClick={() => !syncing && setActiveModal('update')}
            >
              Update stockrooms
            </a>
          </small>
        )}
      </td>
      <td>
        <LgcSwitch
          data-lg-id="avantor-switch"
          checked={connected}
          disabled={syncing}
          onChange={() => setActiveModal(connected ? 'disconnect' : 'connect')}
        />
      </td>
      <td>
        <small className="block">Enable this function to allow connection to Avantor's IM.</small>
        <small className="block">
          By enabling this integration you are creating a sync between inventory and storage management modules in
          Labguru and Avantor stock rooms
        </small>
        {status && (
          <small className="block">
            {settings && (
              <span className="me-2">
                <strong>Connected to:</strong> {settings.org_name}
              </span>
            )}
            <strong data-lg-id={`avantor-status-${status}`}>Status:</strong> {status}
          </small>
        )}
      </td>

      <AvantorConnectModal
        open={activeModal === 'connect'}
        onClose={() => setActiveModal(null)}
        onConnect={() => handleModalSubmit(AvantorSyncStatus.Connecting)}
      />

      <AvantorDisconnectModal
        open={activeModal === 'disconnect'}
        onClose={() => setActiveModal(null)}
        onDisconnect={() => handleModalSubmit(AvantorSyncStatus.Disconnecting)}
      />

      <AvantorUpdateStockroomsModal
        open={activeModal === 'update'}
        selectedStockrooms={settings?.stockroom_ids ?? []}
        onClose={() => setActiveModal(null)}
        onUpdated={() => handleModalSubmit(AvantorSyncStatus.Updating)}
      />
    </>
  );
};

export default AvantorIntegrationSettings;
