import React, { useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { selectCloningObject } from '../slices/cloneSlice';
import LgcEditor, { getEditorProps } from '../../editor/LgcEditor';
import axios from 'axios';
import { updateEnzymes,updateCaretPosition} from '../slices/vectorSlice';

export default function LgcCloneVector(props) {
  const dispatch = useDispatch();
  const cloningObject = useSelector(selectCloningObject);
  const base_sequence_data_url = `${window.location.origin}/api/v1/molecular_biology_cloning/${cloningObject.id}/base_sequence_data`;
  const editorRef = useRef();
  const caretPosition = useSelector((state) => state.vector.caretPosition);

  const enzymes = useSelector((state) => state.vector.enzymes);

  const [options,setOptions] = useState([]);
  const editorProps = getEditorProps();
  editorProps.onSelectionOrCaretChanged = function(e) {
    onSelectionOrCaretChanged(e.caretPosition);
  }


  const onSelectionOrCaretChanged = (caretPosition) => {
    if(caretPosition != -1)
      dispatch(updateCaretPosition(caretPosition));
  }

  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      const res = options.filter((option) => option.value.includes(inputValue));
      if (res.length > 0) {
        // If matching options are found immediately, resolve with them
        resolve(res);
      } else {
        // If no matching options are found, make an asynchronous request
        axios.get(`${window.location.origin}/api/v1/molecular_biology_cloning/${cloningObject.id}/enzyme_info?search_string=${inputValue}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.error('Error fetching options:', error);
            resolve([]); // Resolve with an empty array if there's an error
          });
      }
    });
  };

  const updateEditor = (options) => {
    const state = editorRef.current.editor.getState();
    const updatedState = {
      ...state,
      restrictionEnzymes: {
        ...state.restrictionEnzymes,
        filteredRestrictionEnzymes: [...options],
      },
    }
    editorRef.current.editor.updateEditor(updatedState);
    return updatedState;
  }
  const handleOptionChange = (updatedOptions) => {
    const updatedState = updateEditor(updatedOptions)
    console.log(updatedState.restrictionEnzymes.filteredRestrictionEnzymes)
    dispatch(updateEnzymes(updatedState.restrictionEnzymes.filteredRestrictionEnzymes))
  }

  const handleLinearizationMethodRadio = (event) => {
    props.setLinMethod(parseInt(event.target.value));
  }

  const editorCallbacks = (editor, data) => {
    editor.updateEditor(data);
  }
  const Instructions = () => {
    return (
      <>
        {props.linMethod ?
          <h1 className='item-title'>{I18n.t('molecular_biology_cloning.lgc_clone_vector_1')} </h1> :
          <h1 className='item-title'>{I18n.t('molecular_biology_cloning.lgc_clone_vector_2')} </h1>}
      </>
    )
  }
  const handleError = (error) => {
    Flash.error(error);
  }

  useEffect(() => {
    if(editorRef.current && editorRef.current.editor){
      if(editorRef.current && editorRef.current.editor){
        const state = editorRef.current.editor.getState();
        if(state){
          const key = Object.keys(state.selectedAnnotations.idMap)[0];
          const enzymeName = state.selectedAnnotations.idMap[key]
          if(enzymeName){
              handleOptionChange([{ value: enzymeName.name.toLowerCase(),valueForGibson: enzymeName.name,label:  `${enzymeName.name.toLowerCase()} (${enzymeName.numberOfCuts}) cuts `}]);
          }
        }
      }
    }

  },[caretPosition]);

  useEffect(() => {
    props.handleInstructions(Instructions);

    axios.get(`${window.location.origin}/api/v1/molecular_biology_cloning/${cloningObject.id}/enzyme_info`)
    .then(response => {
      const data = response.data;
      if(!data.error){
        setOptions(response.data);
      }else{
        Flash.error(data.error);
      }
    })
  },[])

  return (
    <>
      <div className='method'>
        <div className='form-group'>
          <div className='radio-control'>
            <label className='radio-control__label'>
              Linearized by PCR
              <input data-lg-id= "linearized_by_pcr_input" disabled className='radio-control__input' type="radio" name="linearization_method" value="1" checked= {props.linMethod == 1} onChange={handleLinearizationMethodRadio}/>
            </label>
          </div>

          <div className='radio-control'>
            <label className='radio-control__label'>
              Linearized by restriction
              <input data-lg-id= "linearized_by_restriction_input" className='radio-control__input' type="radio" name="linearization_method" value="0" checked={props.linMethod == 0} onChange={handleLinearizationMethodRadio}/>
            </label>
          </div>
          <div data-lg-id= "async_select_enzymes" className='select-group' style={{'width': '50%'}}>
            <AsyncSelect
                id="select_enzymes"
                loadOptions={promiseOptions}
                defaultOptions={options}
                isMulti
                value={enzymes}
                onChange={handleOptionChange}
              ></AsyncSelect>
          </div>
        </div>
      </div>
      <LgcEditor
        url={base_sequence_data_url}
        editorProps={editorProps}
        editorRef={editorRef}
        editorCallbacks={editorCallbacks}
        handleError={handleError}
      />
    </>
  )
}
