import { createSlice } from "@reduxjs/toolkit";

const initialState = window.LgFlash.pending || []; // see noty_adapter.js for reference

const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    enqueue: (state, action) => {
      state.push(action.payload);
    },
    prepend: (state, action) => {
      state.unshift(action.payload);
    },
    dismiss: (state, action) => {
      const idToRemove = action.payload;
      return state.filter(toast => toast.id !== idToRemove);
    },
    replace: (_state, action) => {
      return [action.payload];
    },
    dismissAll: (_state) => {
      return [];
    }
  },
});

export const { enqueue, prepend, dismiss, replace, dismissAll } = toastsSlice.actions;

export default toastsSlice.reducer;
