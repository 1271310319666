import React from 'react';

import { formatDateTime, getMemberFullName } from './Utils/LgcSfHelperMethods';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

const LgcSfIndex = ({ flows, members, deleteFlow, handleEditFlow, handlePage, handleShowFlow, applied_flows_ids }) => {

  const COLUMNS = [
    {
      field: 'id',
      title: I18n.t("signature_flow.index_headers.id"),
      width: '150px'
    },
    {
      field: 'name',
      title: I18n.t("signature_flow.index_headers.name"),
      render: (dataItem) => (
        <td>
          <a
            href={""}
            onClick={(event) => {
              event.preventDefault();
              handleShowFlow(dataItem.dataItem)
            }}
          >
            {dataItem.dataItem.name}
          </a>
        </td>
      ),
    },
    {
      field: 'owner_id',
      title: I18n.t("signature_flow.index_headers.owner_id"),
      render: (dataItem) => (
        <td>
          <a href={`/admin/members/${dataItem.dataItem.owner_id}`}>
            {getMemberFullName(members, dataItem.dataItem.owner_id)}
          </a>
        </td>
      ),
    },
    {
      field: 'created_at',
      title: I18n.t("signature_flow.index_headers.created_at"),
      render: (dataItem) => (
        <td>
          {formatDateTime(dataItem.dataItem.created_at)}
        </td>
      )
    },
    {
      field: 'updated_at',
      title: I18n.t("signature_flow.index_headers.updated_at"),

      render: (dataItem) => (
        <td>
          {formatDateTime(dataItem.dataItem.updated_at)}
        </td>
      )
    },
    {
      field: '',
      title: '',
      width: '150px',
      render: (dataItem) => {
        const isIncluded = applied_flows_ids.includes(dataItem.dataItem.id);
        return (
          <td>
            {isIncluded ? (
              <span>{I18n.t("signature_flow.applied")}</span>
            ) : (
              <button onClick={() => handleEditFlow(dataItem.dataItem)}>
                <i className='fa fa-edit'></i>{I18n.t("signature_flow.edit")}
              </button>
            )}
          </td>
        );
      },
    },
    {
      field: '',
      title: '',
      width: '150px',
      render: (dataItem) => {
        const isIncluded = applied_flows_ids.includes(dataItem.dataItem.id);
        return (
          <td className='delete_row' >
            {(!isIncluded && dataItem.dataItem.id !== 1) && (
              <button onClick={() => deleteFlow(dataItem.dataItem)}>
                <i className='fa-solid fa-trash-can' ></i> {I18n.t("signature_flow.delete")}
              </button>
            )
            }
          </td>
        )
      }
    }
  ]

  return (
    <div data-lg-id='signature_flow_index_grid'>
      <div className='lgc-signature-flow-header'>
      <div className="message-block message-block--info margin-bottom-add">
        <p className="message-block__title">Signature process settings</p>
        <div className="message-block__content"></div>
        <p>
          {I18n.t("signature_flow.flow_info")}
        </p>
        <a href="https://help.labguru.com/en/articles/6383903-customize-signature-flows-to-streamline-efficiency-to-a-process" target="_blank" rel="noopener noreferrer">
            Learn more
        </a>

      </div>
        <button onClick={() => handlePage('signatureFlowForm')} data-lg-id='signatureFlow-add-flow' className='btn btn-primary lg-button add'>
          {I18n.t("signature_flow.add_new_flow")}
        </button>
      </div>


      <Grid data={flows} resizable={true} fixedScroll={true}>
        {COLUMNS.map((column) =>
          <Column
            key={column.field}
            field={column.field}
            title={column.title}
            cell={column.render}
            width={column.width}
          />
        )}
      </Grid>
    </div>
  );
};

export default LgcSfIndex;
