import React, { FC, InputHTMLAttributes } from 'react';

import { classMap } from '@common/utils';
import LgcLabel from './LgcLabel';

interface LgcFormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const LgcFormField: FC<LgcFormFieldProps> = ({ label, className, name, type = 'text', placeholder, ...restProps }) => {
  return (
    <div className="form-group">
      {label && (
        <LgcLabel htmlFor={name} required={restProps.required}>
          {label}
        </LgcLabel>
      )}
      <input
        className={classMap({
          'form-control': true,
          [className ?? '']: true,
        })}
        id={name}
        type={type}
        name={name}
        placeholder={placeholder ?? `${label}...`}
        {...restProps}
      />
    </div>
  );
};

export default LgcFormField;
