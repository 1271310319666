import React, { FC, InputHTMLAttributes } from 'react';

interface LgcFormFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const LgcFormField: FC<LgcFormFieldProps> = ({ label, name, placeholder, ...restProps }) => {
  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <input className="form-control" id={name} name={name} placeholder={placeholder ?? `${label}...`} {...restProps} />
    </div>
  );
};

export default LgcFormField;
