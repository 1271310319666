import React, { FC } from 'react';

import { classMap } from '@common/utils';

interface LgcSkeletonProps {
  width?: number;
  height?: number;
  className?: string;
  variant?: 'rect' | 'circle' | 'rounded';
}

const LgcSkeleton: FC<LgcSkeletonProps> = ({ variant = 'rounded', className, width, height }) => {
  return <div className={classMap({
    'lg-skeleton': true,
    [`lg-skeleton--${variant}`]: true,
    [className ?? '']: true,
  })} style={{ width, height }} />;
};

export default LgcSkeleton;
