import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { classMap } from '@common/utils';
import LgcToast from './LgcToast';

interface LgcModalBaseProps {
  open: boolean;
  children?: ReactNode;
  dialogClassName?: string;
  dismissible?: boolean;
  size?: 'sm' | 'md' | 'lg';
  lgId?: string;
  onDismiss?: () => void;
}

export interface LgcModalProps extends LgcModalBaseProps {
  title?: string;
  error?: string | null;
  headerClassName?: string;
  contentClassName?: string;
  footerClassName?: string;
  buttons?: ReactNode[];
  onErrorDismiss?: () => void;
}

interface LgcModalHeaderProps {
  title: string;
  className?: string;
}

interface LgcModalBodyProps {
  children?: ReactNode;
  className?: string;
}

interface LgcModalFooterProps {
  children?: ReactNode;
  className?: string;
}

const overlayVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const dialogVariants = {
  hidden: { y: '-30%', opacity: 0 },
  visible: { y: '0', opacity: 1 },
};

const transition = { duration: 0.3, ease: 'easeInOut' };

export const LgcModalBase: FC<LgcModalBaseProps> = ({
  open,
  children,
  dialogClassName,
  dismissible,
  size,
  lgId,
  onDismiss,
}) => {
  useEffect(() => {
    if (open && dismissible) {
      const handleKeyDown = (e: KeyboardEvent) => e.key === 'Escape' && onDismiss?.();

      document.addEventListener('keydown', handleKeyDown);

      return () => document.removeEventListener('keydown', handleKeyDown);
    }
  }, [open, dismissible, onDismiss]);

  return createPortal(
    <AnimatePresence>
      {open && (
        <motion.div
          className="lgc-modal-overlay"
          exit="hidden"
          initial="hidden"
          animate="visible"
          transition={transition}
          variants={overlayVariants}
          onClick={e => {
            if ((e.target as HTMLElement)?.classList.contains('lgc-modal-overlay') && dismissible) {
              onDismiss?.();
            }
          }}
        >
          <motion.div
            className={classMap({
              'lgc-modal-dialog': !dialogClassName,
              [`lgc-modal-dialog--${size}`]: !dialogClassName && !!size,
              [dialogClassName ?? '']: !!dialogClassName,
            })}
            data-lg-id={lgId}
            exit="hidden"
            initial="hidden"
            animate="visible"
            transition={transition}
            variants={dialogVariants}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  ) as ReactElement;
};

const LgcModalHeader: FC<LgcModalHeaderProps> = ({ title, className }) => {
  return (
    <div className={`ui-dialog-titlebar ${className ?? ''}`}>
      <p className="ui-dialog-title mb-0">{title}</p>
    </div>
  );
};

const LgcModalBody: FC<LgcModalBodyProps> = ({ children, className }) => {
  return <div className={`ui-dialog-content ${className ?? ''}`}>{children}</div>;
};

const LgcModalFooter: FC<LgcModalFooterProps> = ({ children, className }) => {
  return <div className={`ui-dialog-buttonpane flex mt-auto ${className ?? ''}`}>{children}</div>;
};

const LgcModal: FC<LgcModalProps> = ({
  children,
  error,
  title,
  buttons,
  headerClassName,
  contentClassName,
  footerClassName,
  onErrorDismiss,
  ...restProps
}) => {
  return (
    <LgcModalBase {...restProps}>
      {title && <LgcModalHeader className={headerClassName} title={title} />}

      <LgcModalBody className={contentClassName}>
        <AnimatePresence>
          {error && (
            <LgcToast dismissible={!!onErrorDismiss} content={error} level="danger" onDismiss={onErrorDismiss} />
          )}
        </AnimatePresence>

        {children}
      </LgcModalBody>

      {buttons && <LgcModalFooter className={footerClassName}>{buttons}</LgcModalFooter>}
    </LgcModalBase>
  );
};

export default LgcModal;
