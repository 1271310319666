import { Controller } from "@hotwired/stimulus"
import  ReactRailsUJS from "react_ujs"

// eslint-disable-next-line no-undef
const componentRequireContext = require.context("../components", true)
ReactRailsUJS.useContext(componentRequireContext)

// Connects to data-controller="react-component"
export default class extends Controller {
  connect() {
    ReactRailsUJS.mountComponents(this.element.parentNode)
  }

  disconnect() {
    ReactRailsUJS.unmountComponents(this.element.parentNode)
  }
}
