import { configureStore } from '@reduxjs/toolkit';

import stepperReducer from '../slices/steps/stepperSlice';
import settingsReducer from '../slices/settingsSlice';
import vectorReducer from '../slices/vectorSlice';
import fragmentsReducer from '../slices/fragmentsSlice';
import assembleReducer from '../slices/assembleSlice';
import resultsReducer from '../slices/resultsSlice';
import cloneReducer from '../slices/cloneSlice';


export default configureStore({
  reducer: {
    stepper: stepperReducer,
    settings: settingsReducer,
    vector: vectorReducer,
    fragments: fragmentsReducer,
    assemble: assembleReducer,
    results: resultsReducer,
    clone: cloneReducer
  }
});
