import React, { useContext, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { LgcSfContext } from '../../LgcSfContext'

import { LgcSfDndItemTypes } from './LgcSfDndItemTypes'
import LgcSfStep from './LgcSfStep'

export const Card = ({ id, index, moveCard, step, showStepContent, previewMode }) => {
  const { itemApplied } = useContext(LgcSfContext);
  const { page } = useContext(LgcSfContext);

  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    canDrop: (!itemApplied && page !== "LgcSfShow"),
    accept: LgcSfDndItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current ? ref.current.getBoundingClientRect() : null;
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    canDrag: (!itemApplied && page !== "LgcSfShow"),
    type: LgcSfDndItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const dragabbleSelector = isDragging ? 'lgc-draggable-step-container lgc-draggable-step-container--active' : 'lgc-draggable-step-container';
  drag(drop(ref))
  return (
    <div className='lgc-draggable-step-wrapper'>
      <div ref={ref} className={dragabbleSelector} data-handler-id={handlerId}>
        <LgcSfStep
          step={step}
          index={index}
          showStepContent={showStepContent}
          previewMode={previewMode}
          />
      </div>
    </div>
  )
}
