import React, { FC, useEffect, useState } from 'react';

import { LgcButton, LgcInput, LgcModal, LgcSelect, LgcTextarea } from '@common/components';
import { useMutation } from '@common/hooks';
import * as api from './api';
import { colorSelectOptions, iconSelectOptions } from './consts';
import { Flag } from './types';

interface FlagFormModalProps {
  open: boolean;
  value: Flag | null;
  mode: 'create' | 'edit';
  onClose: () => void;
  onSave: (flag: Flag) => void;
}

const defaultValue: Partial<Flag> = {
  title: '',
  description: '',
  color: 'green',
  icon: 'star',
};

const FlagFormModal: FC<FlagFormModalProps> = ({ open, mode, value, onClose, onSave }) => {
  const [error, setError] = useState<string | null>(null);
  const [flag, setFlag] = useState(value ?? defaultValue);

  useEffect(() => {
    setError(null);
    setFlag(value ?? defaultValue);
  }, [open]);

  const updateValue = (update: Partial<Flag>) => setFlag(s => ({ ...s, ...update }));

  const createFlag = useMutation({
    mutationFn: () => api.createFlag(flag),
    onSuccess: onSave,
    onError: setError,
  });

  const updateFlag = useMutation({
    mutationFn: () => api.updateFlag(flag),
    onSuccess: onSave,
    onError: setError,
  });

  return (
    <LgcModal
      size="sm"
      open={open}
      error={error}
      onDismiss={onClose}
      title={mode === 'create' ? 'Add new flag' : 'Edit flag'}
      lgId="flag-form-modal"
      onErrorDismiss={() => setError(null)}
      buttons={[
        <LgcButton small key="cancel" className="ms-auto" color="white" onClick={onClose}>
          Cancel
        </LgcButton>,
        <LgcButton
          small
          key="next"
          data-lg-id="flag-form-modal-save-button"
          loading={createFlag.isLoading || updateFlag.isLoading}
          onClick={() => (mode === 'create' ? createFlag.mutate() : updateFlag.mutate())}
          disabled={!flag.title}
        >
          Save
        </LgcButton>,
      ]}
    >
      <LgcInput
        required
        name="title"
        label="Flag name"
        placeholder="Enter flag name..."
        value={flag.title}
        disabled={mode === 'edit'}
        onChange={e => updateValue({ title: e.target.value })}
      />

      <LgcTextarea
        name="description"
        label="Description"
        placeholder="Enter description (optional)..."
        value={flag.description}
        onChange={e => updateValue({ description: e.target.value })}
      />

      <LgcSelect
        name="color"
        label="Color"
        options={colorSelectOptions}
        value={flag.color ?? ''}
        onChange={value => updateValue({ color: value })}
      />

      <LgcSelect
        name="icon"
        label="Icon"
        options={iconSelectOptions}
        value={flag.icon ?? ''}
        onChange={value => updateValue({ icon: value })}
      />
    </LgcModal>
  );
};
export default FlagFormModal;
