import React, {
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from 'react';
import SlimSelect from 'slim-select';
import Settings from 'slim-select/dist/settings';
import { OptionOptional } from 'slim-select/dist/store';

export type LgcSelectOption = OptionOptional;

type LgcSelectSettings = Omit<Partial<Settings>, 'placeholderText'>;

interface BaseLgcSelectProps {
  label?: string;
  name: string;
  disabled?: boolean;
  options: LgcSelectOption[];
  value: string;
  lgId?: string;
  placeholder?: string;
  settings?: LgcSelectSettings;
}

type LgcSelectProps = BaseLgcSelectProps &
  (
    | {
        multiple?: false;
        onChange?: (value: string) => void;
      }
    | {
        multiple: true;
        onChange?: (value: string[]) => void;
      }
  );

const defaultSettings: LgcSelectSettings = {
  showSearch: false,
};

export const LgcSelect: ForwardRefExoticComponent<LgcSelectProps & RefAttributes<SlimSelect | undefined>> = forwardRef(
  ({ label, name, options, disabled, value, lgId, multiple = false, placeholder, settings, onChange }, ref) => {
    const selectRef = useRef(null);
    const slimSelectRef = useRef<SlimSelect>();

    const getNormalizedValue = useCallback(() => {
      return multiple ? slimSelectRef.current!.getSelected() : slimSelectRef.current!.getSelected()[0];
    }, [multiple]);

    useLayoutEffect(() => {
      if (!selectRef.current) return;

      const afterChange = () => {
        onChange?.(getNormalizedValue() as string & string[]);
      };

      slimSelectRef.current = new SlimSelect({
        select: selectRef.current,
        data:  placeholder ? [{ text: placeholder, value: '', placeholder: true }, ...options] : options,
        settings: { ...defaultSettings, ...settings, disabled },
        events: { afterChange },
      });

      slimSelectRef.current.setSelected(value);

      return () => slimSelectRef.current?.destroy();
    }, []);

    useEffect(() => {
      disabled ? slimSelectRef.current?.disable() : slimSelectRef.current?.enable();
    }, [disabled]);

    useEffect(() => {
      slimSelectRef.current?.setSelected(value);
    }, [value]);

    useImperativeHandle(ref, () => slimSelectRef?.current);

    return (
      <div className="form-group">
        {label && <label onClick={() => slimSelectRef.current?.open()}>{label}</label>}
        <select data-lg-id={lgId} id={name} name={name} ref={selectRef} />
      </div>
    );
  }
);

export default LgcSelect;
