import { motion } from 'framer-motion';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';

interface LgcAutoHeightContainerProps {
  children?: ReactNode;
}

const LgcAutoHeightContainer: FC<LgcAutoHeightContainerProps> = ({ children }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState<number | 'auto'>('auto');

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver(entries => {
        setHeight(entries[0].contentRect.height);
      });

      observer.observe(ref.current);

      return () => observer.disconnect();
    }
  }, []);

  return (
    <motion.div
      animate={{ height }}
      style={{ height, overflow: 'hidden' }}
      transition={{ duration: 0.25, ease: 'linear' }}
    >
      <div ref={ref}>{children}</div>
    </motion.div>
  );
};

export default LgcAutoHeightContainer;
