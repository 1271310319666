import { createSlice } from "@reduxjs/toolkit";

import { setStep } from "./steps/stepperSlice";
import { enableVectorStep } from "./vectorSlice";
import { createCloneThunk, updateCloneThunk } from "./cloneSlice";

const initialState = {
  status: 'pending',
  error: null,
  errorDetails: {},
  disabled: false
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    createSettingsStart: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    createSettingsSuccess: (state) => {
      state.status = 'completed';
    },
    disableSettingStep: (state) => {
      state.disabled = true;
    },
    enableSettingStep: (state) => {
      state.disabled = false;
    },
    resetStettingsState: () => {
      return initialState;
    },

  }
});

export const {
  createSettingsStart,
  createSettingsSuccess,
  disableSettingStep,
  enableSettingStep,
  resetStettingsState
} = settingsSlice.actions;

// controller naming convention are broken TODO: update when fixed
export const createSettingsThunk = (payload, callback) => async (dispatch) => {
  dispatch(createSettingsStart());
  dispatch(createCloneThunk(payload,callback));
  dispatch(createSettingsSuccess());

}
export const updateSettingsThunk = (payload) => async (dispatch) => {
  dispatch(createSettingsStart());
  dispatch(updateCloneThunk(payload, callback(dispatch)));
}
const callback = (dispatch) => (data) => {
  dispatch(createSettingsSuccess());
  dispatch(enableVectorStep());
  dispatch(setStep(2));

}

export default settingsSlice.reducer;
