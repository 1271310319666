import { createSlice } from "@reduxjs/toolkit";
import { resetStettingsState } from "../settingsSlice";

const initialState = {
  steps: [
    { id: 1, title: 'Settings', slice: 'settings' },
    { id: 2, title: 'Vector', slice: 'vector' },
    { id: 3, title: 'Fragments', slice: 'fragments' },
    { id: 4, title: 'Assemble', slice: 'assemble' },
    { id: 5, title: 'Review Results', slice: 'results' }
  ],
  currentStepId: 1,
  disableButtons: false
}

// LgcCloneWizard specific stepper slice (each stepper needs its own); LgcStepper is a dumb component
export const stepperSlice = createSlice({
  name: 'stepper',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.currentStepId = action.payload;
    },
    setDisableButtons: (state,action) => {
      state.disableButtons = action.payload;

    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStettingsState, () => initialState);
  }

});

export const {
  setStep,
  setDisableButtons
} = stepperSlice.actions;

export default stepperSlice.reducer;

export const selectAllSteps = (state) => {
  const fullSteps = state.stepper.steps.map((step) => {
    let uiStatus = state[step.slice].status;

    if (step.id == state.stepper.currentStepId && uiStatus != 'loading' ) {
      uiStatus = 'active';
    }
    return { ...step, status: uiStatus, disabled: state[step.slice].disabled }
  });

  return fullSteps;
};

export const selectCurrentStep = (state) => {
  const current = state.stepper.steps.find(step => step.id == state.stepper.currentStepId);

  return current;
}
