import React, { useContext, useState, useEffect } from 'react';
import { LgcSfContext } from '../LgcSfContext';

import { LgcSfStepsWrapper } from './StepsWrapper/LgcSfStepsWrapper';
import LgcSfForm from './LgcSfForm';
import { getNewSigFlow } from '../Utils/LgcSfApiMethods'

const LgcSfFormWrapper = ({ editFlowData, witnessersOptions, handleNewFlow, updateFlow, handlePage, stepTemplate }) => {

  const [stepPosition, setStepPosition] = useState('0')
  const [readyToRender, setReadyToRender] = useState(false);

  const { itemApplied } = useContext(LgcSfContext);
  const { signatureFlow, setSignatureFlow } = useContext(LgcSfContext);
  const { formMode } = useContext(LgcSfContext);
  const {currentStepIndex, setCurrentStepIndex} = useContext(LgcSfContext);


  useEffect(() => {
    initAdvancedFlow();
  }, []);

  const generateUniqueIdentifier = () => {
    return Math.random().toString(36).substr(2, 9);
  }

  const initAdvancedFlow = async () => {
    if (formMode === 'new') {
      const res = await getNewSigFlow({ advanced: true });
      setSignatureFlow({ ...res.data.item })
    };
    setCurrentStepIndex(-1);
    setReadyToRender(true);
  };

  const addStep = (e) => {
    e.preventDefault();
    if (signatureFlow.steps && signatureFlow.steps.length >= 5) {
      return;
    }
    const length = signatureFlow.steps ? signatureFlow.steps.length : 0;
    let stepTemplateUpdated = { ...stepTemplate };
    stepTemplateUpdated.position = length;
    stepTemplateUpdated.id = generateUniqueIdentifier(); //TODO: generateUniqueIdentifier may not guarantee truly unique identifiers.
    stepTemplateUpdated.witnessers = [];
    const updatedSteps = signatureFlow.steps ? [...signatureFlow.steps, stepTemplateUpdated] : [stepTemplateUpdated]; // make a copy of the original steps array
    const updatedItem = { ...signatureFlow, steps: updatedSteps }; // make a copy of the original item object with updated steps
    setSignatureFlow(updatedItem)

    setCurrentStepIndex(updatedSteps.length - 1)
  }

  const showStepContent = (index) => {
    setCurrentStepIndex(index)
  };

  const handleUpdateStep = (key, value) => {
    const updatedSteps = [...signatureFlow.steps]; // make a copy of the original steps array
    const updatedStep = { ...updatedSteps[currentStepIndex] }; // create a new object by spreading the existing object
    updatedStep[key] = value; // update the property on the new object
    updatedSteps[currentStepIndex] = updatedStep; // update the specific index in the copied array
    const updatedItem = { ...signatureFlow, steps: updatedSteps }; // make a copy of the original item object with the updated steps
    setSignatureFlow(updatedItem)
  };

  const setItemValue = (key, value) => {
    setSignatureFlow((prevItem) => ({
      ...prevItem,
      [key]: value
    }))
  };

  return (
    <>
      {readyToRender &&
        <div className='lgc-signature-flow-form'>
          <div className='col-md-4 lgc-dragable-steps'>
            <form>
                <div className='row row-no-gutters'>
                  <div className='col-md-12 text-center'>
                    {(!itemApplied && (signatureFlow.steps === null || (signatureFlow && signatureFlow.steps && signatureFlow.steps.length < 5))) &&
                      <button onClick={addStep} id='add-step' className='lg-button add small lg-button-ghost'>{I18n.t("signature_flow.add_step_title")}</button>
                    }
                  </div>
                </div>
                <LgcSfStepsWrapper
                  showStepContent={showStepContent}
                />
            </form>
          </div>
          <div className='col-md-8'>
              <LgcSfForm
                signatureFlow={signatureFlow}
                currentStep={signatureFlow.steps ? signatureFlow.steps[currentStepIndex] : null}
                advanced={(signatureFlow.steps && signatureFlow.steps.length > 0) ? true : false}
                editFlowData={editFlowData}
                position={stepPosition}
                handleUpdateStep={handleUpdateStep}
                setItemValue={setItemValue}
                witnessersOptions={witnessersOptions}
                updateFlow={updateFlow}
                handlePage={handlePage}
                handleNewFlow={handleNewFlow}
              />
            </div>
          </div>
      }
    </>
  )
};

export default LgcSfFormWrapper;
