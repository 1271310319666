import React, {useEffect} from 'react'
import {useQuery, gql} from '@apollo/client'
import _ from 'lodash';
import ShowInfoRow from './ShowInfoRow';

const HumanizeKey = (key, customFields) => {
  let customFieldId = key.split('custom')[1];
  if (customFieldId){
    customFieldId = customFieldId * 1;
    let field = _.find(customFields, {fieldId: customFieldId});
    if (field && field.fieldName)
      return field.fieldName;
    else
      return key;
  } else {
    switch (key) {
      case 'id':
        return 'ID'
      case 'autoName':
        return 'SysID'
      case 'createdAt':
        return 'Created at'
      default:
        return key
    }
  }
}

const ShowInfo = ({ itemId, itemType, query, info }) => {
  // const thequery = `query ($itemId: ID!){` + itemType + `(id: $itemId){` + queryFields + `}}`
  const GET_ITEM = gql(query);
  const { loading, error, data } = useQuery(GET_ITEM, { variables: { itemId } });
  useEffect(() => {
    if (data)
      console.log(data);
  }, [data])

  if (loading)
    return <div>Loading...</div>
  if (error)
    return <div className='lg-error-message'>Couldn't load data</div>

  return (
    <table className='info-table width100 word_break'>
      <tbody>
        { data && <ShowInfoRow data={data[itemType]} info={info} ></ShowInfoRow>}
      </tbody>
    </table>
  )
}

export default ShowInfo
