import React, { useContext, useState } from "react";
import { LgcSfContext } from "../LgcSfContext";

const LgcSfFormActions = () => {

  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const { formMode, setFormMode } = useContext(LgcSfContext);
  const { setPage } = useContext(LgcSfContext);

  const handleCancel = () => {
    setIsCancelConfirmationOpen(true);
  };

  const handleCancelConfirmation = () => {
    // Close the confirmation dialog
    setIsCancelConfirmationOpen(false);
    setFormMode('new')
    setPage('index')
  };

  const handleCancelCancel = () => {
    // Close the confirmation dialog
    setIsCancelConfirmationOpen(false);
  };

  return (
    <div className='lgc-signature-flow-form-actions' style={{ display: 'flex', justifyContent: 'right' }}>

      {!isCancelConfirmationOpen &&
        <>
          <input className='white_button lg-button small blue' type="submit" value="Save"></input>
          <button className='white_button lg-button small white' onClick={handleCancel}>{I18n.t("signature_flow.cancel")}</button>
        </>
      }

      {/* Cancel confirmation dialog */}
      {isCancelConfirmationOpen && (
        <div style={{ textAlign: 'end' }}>
          <p>{formMode === 'new' ? 'Are you sure you want to cancel signature Flow design ?' : 'Are you sure you want to cancel changes made to signature flow design'}</p>
          <button className='white_button lg-button small white' onClick={handleCancelConfirmation}>{I18n.t("signature_flow.yess")}</button>
          <button className='white_button lg-button small blue' onClick={handleCancelCancel}>{I18n.t("signature_flow.noo")}</button>
        </div>
      )}

    </div>
  )
};

export default LgcSfFormActions;
