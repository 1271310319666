import React, { FC, HTMLAttributes } from 'react';

import { classMap } from '@common/utils';

export type LgcButtonColor = 'purple' | 'secondary' | 'green' | 'red' | 'white';

interface LgcButtonProps extends HTMLAttributes<HTMLButtonElement> {
  color?: LgcButtonColor;
  small?: boolean;
  loading?: boolean;
  disabled?: boolean;
  lgId?: string;
}

const LgcButton: FC<LgcButtonProps> = ({ color, small, disabled, loading, children, className, lgId, ...restProps }) => {
  return (
    <button
      data-lg-id={lgId}
      disabled={disabled}
      className={classMap({
        'lg-button': true,
        small: small,
        loading: loading,
        disabled: disabled,
        [color ?? '']: true,
        [className ?? '']: true,
      })}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default LgcButton;
