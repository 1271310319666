export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface AvantorCredentials {
  email: string;
  xapikey: string;
  password: string;
  server_url: string;
}

export interface AvantorSettings extends AvantorCredentials {
  org_name: string;
  org_id: string;
  stockroom_ids: string[];
}

export interface AvantorAPIResponse<T = Response> {
  data: T;
  error: string;
  success: boolean;
}

export interface AvantorFetchOrganizationsResponse {
  imeorganizationBaseDTO: AvantorOrganizationBaseDTO[];
}

export interface AvantorOrganizationBaseDTO {
  id: string;
  orgName: string;
}

export interface AvantorStockroom {
  id: string;
  stockroomName: string;
  childStockroom: AvantorStockroom[];
}

export enum AvantorSyncStatus {
  Connecting = 'connecting',
  Updating = 'updating',
  Disconnecting = 'disconnecting',
  Synced = 'synced',
  Disconnected = 'disconnected',
}
