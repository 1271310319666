import React from 'react';
import { Provider } from 'react-redux';

import LgcPortal from './integration/LgcPortal';

import store from './LgcToaster/store/store';
import LgcToasterQ from './LgcToaster/LgcToasterQ';

export default function LgcToaster() {
  return (
    <Provider store={store}>
      <LgcPortal>
        <div className="lgc-toaster">
          <LgcToasterQ />
        </div>
      </LgcPortal>
    </Provider>
  )
}


