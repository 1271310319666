import {
  AvantorAPIResponse,
  AvantorCredentials,
  AvantorFetchOrganizationsResponse,
  AvantorSettings,
  AvantorStockroom,
  AvantorSyncStatus,
  HttpMethod,
} from './types';

const BASE_URL = '/api/v1/avantor';

const getToken = (): string => {
  return window.cookie.getAttr(`front_end_token_${window.currentAccount.id}`);
};

const fetchApi = async <T = undefined>(url: string, method: HttpMethod, body?: object): Promise<T> => {
  const response = await fetch(`${BASE_URL}/${url}?token=${getToken()}`, {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: body ? JSON.stringify(body) : undefined,
  });

  const json = (await response.json()) as AvantorAPIResponse<T>;

  if (!response.ok) {
    throw new Error(json?.error ?? 'An error occurred');
  }
  return json.data;
};

export const fetchOrganizations = (credentials: AvantorCredentials): Promise<AvantorFetchOrganizationsResponse> => {
  return fetchApi('fetch_organizations', 'POST', credentials);
};

export const getStockrooms = (): Promise<AvantorStockroom[]> => {
  return fetchApi('get_stockrooms', 'GET');
};

export const getSettings = (): Promise<AvantorSettings> => {
  return fetchApi('get_settings', 'GET');
};

export const fetchStockrooms = (settings: Omit<AvantorSettings, 'stockroom_ids'>): Promise<AvantorStockroom[]> => {
  return fetchApi('fetch_stockrooms', 'POST', settings);
};

export const updateStockrooms = (stockroomIds: string[]) => {
  return fetchApi('update_stockrooms', 'PUT', { stockroom_ids: stockroomIds });
};

export const connect = (settings: AvantorSettings) => {
  return fetchApi('connect', 'POST', settings);
};

export const getSyncStatus = (): Promise<AvantorSyncStatus> => {
  return fetchApi('get_sync_status', 'GET');
};

export const disconnect = () => {
  return fetchApi('disconnect', 'DELETE');
};
