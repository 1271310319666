import React, { useRef, useState, useEffect } from "react";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { setDisableButtons } from "../LgcCloneWizard/slices/steps/stepperSlice";

export default function LgcEditor({ url, editorProps = null, editorRef = null, editorCallbacks, handleError = null}){
  const ref = editorRef || useRef();
  const dispatch = useDispatch();
  const editorUrl = useRef();
  const [showSpinner,setShowSpinner] = useState(null);
  const props = editorProps || getEditorProps();

  useEffect(() => {
    setShowSpinner(true);
    if (ref.current && url) {
      editorUrl.current = url
      dispatch(setDisableButtons(true));
      axios.get(url)
        .then(response => {
          const data = response.data;
          if(!ref.current.editor){
            ref.current.editor = window.createVectorEditor(ref.current, props);
          }
          if(editorCallbacks){
            editorCallbacks(ref.current.editor,data);
          }
          setShowSpinner(false);


        })
        .catch(error => {
          if(handleError)
            handleError(error);

        }).finally(() => {
          dispatch(setDisableButtons(false));

        });
    }
  },[url]);

  return (
      <div className="dna-widget"  >
        <div id="dna-container" ref={ref} style={{ display: showSpinner ? 'none' : '' }}></div>
        <div className="spinner-container " style={{ display: !showSpinner ? 'none' : 'flex', width: '100%', height: '100%'}}>
          <i className="fa fa-spin fa-spinner fa-3x" style={{margin: 'auto'}}></i>
        </div>
      </div>

  )
}
export function getEditorProps() {
  return {
    shouldAutosave: true, //by default the editor does not autosave, setting this to true will trigger the onSave callback after any change to the sequenceData
    handleFullscreenClose: function() {
      ref.current.editor.close()
    },
    showReadOnly: false, //default true
    showMenuBar: false,
    disableSetReadOnly: true, //default false
    beforeAnnotationCreate: function({ //also works for edits (!)
      annotationTypePlural, //features/parts/primers
      annotation, //annotation info
      props //general props to the dialog
    }){
      //a handler to hook into when annotations (features/primers/parts) are being created
    },
    PropertiesProps: {
      // the list of tabs shown in the Properties panel
      propertiesList: [
      ]
    },
    ToolBarProps: {
        toolList: [
        ]
      },
      StatusBarProps: {
        showCircularity: false,
        showReadOnly: false,
        showAvailability: false
      },
      onDigestSave: function() {} //tnr: NOT YET IMPLEMENTED
  };
}
