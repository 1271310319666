
import React, { useContext } from 'react'
import { LgcSfContext } from '../../LgcSfContext';
import { isWitnessStep } from '../../Utils/LgcSfHelperMethods'

const LgcSfName = ({
  editFlowData,
  currentStep,
  advanced,
  setItemValue
}) => {

  const { signatureFlow } = useContext(LgcSfContext);
  const { currentStepIndex } = useContext(LgcSfContext);

  const nameHandler = (e) => {
    setItemValue('name', e.target.value)
  };

  return (
    <div className='form-group'>

      <input
        data-lg-id='signature_flow_name_input'
        className='lgc-signature-flow-title'
        disabled={editFlowData.disable_all}
        value={signatureFlow.name}
        onChange={nameHandler}
        id="flowName"
        required="required"
        autoComplete="off"
        maxLength="255"
        type="text"
        placeholder='Name cannot be blank!'
      />

      {(advanced && !isWitnessStep(currentStepIndex)) &&
        <h4>{I18n.t("signature_flow.form.step_name", { step_name: currentStep.role_of_signers})}</h4>
      }
      {(advanced && isWitnessStep(currentStepIndex)) &&
        <h4>{I18n.t("signature_flow.form.step_name", { step_name: I18n.t("signature_flow.steps.witness_step_title")})}</h4>
      }
    </div>
  )
}

export default LgcSfName;
