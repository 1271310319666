import React, { useContext } from "react";
import Select from 'react-select'
import { LgcSfContext } from "../../LgcSfContext";

import { isWitnessStep, stepIsNotValid } from '../../Utils/LgcSfHelperMethods'


const LgcSfWitnessers = ({ options, witnessersStepHandler, logicHandler, currentStep, setItemValue, logicId }) => {

  const { itemApplied } = useContext(LgcSfContext);
  const { signatureFlow } = useContext(LgcSfContext);
  const { currentStepIndex } = useContext(LgcSfContext);
  const { errors } = useContext(LgcSfContext);

  const witnessersItemHandler = (e) => {
    isWitnessStep(currentStepIndex) ? setItemValue('witnessers', e) : witnessersStepHandler(e);
  };
  return (
    <>
      <label className='inline-label margin-bottom-add margin-top-add required' htmlFor='witnessers'>
        {I18n.t("signature_flow.form.who_can_witness_or_sign", { action:
          isWitnessStep(currentStepIndex) ? I18n.t("signature_flow.witness") : "Sign"
          })}
      </label>

      <div className='logics_list'>

        <div className='form-group'>
          <label style={{ display: 'inline' }} htmlFor='selected_members'>
            <input
              checked={logicId() === 1}
              onChange={logicHandler}
              type='radio' value='1'
              name='selected_members'
              data-lg-id='signature_flow_form_logic_id_1'
              className='margin-right-add'
              disabled={itemApplied}
            />
            {I18n.t("signature_flow.form.logic_1")}
          </label>
        </div>

        {(logicId() === 1 && options.length > 0) &&
          <div className='form-group select_members_for_witness'>
            <label className="error-label" style={{ color: 'red' }}>{stepIsNotValid(errors, currentStepIndex)}</label>
            <Select
              onChange={witnessersItemHandler}
              getOptionLabel={(option) => option.name} getOptionValue={(option) => option.id}
              menuPosition="fixed"
              menuPlacement="auto"
              isMulti options={options}
              value={(isWitnessStep(currentStepIndex)) ? signatureFlow.witnessers : currentStep.witnessers}
              className='signature_flow_form_select_witnessers_signers w100'
              isDisabled={itemApplied}
              placeholder='Select members and/or teams'
              />
          </div>
        }

        <div className='form-group'>
          <label style={{ display: 'inline' }}>
            <input
              style={{ cursor: 'pointer' }}
              checked={logicId() === 2}
              onChange={logicHandler}
              type="radio" value="2" name="part_of_project"
              className='margin-right-add'
              data-lg-id='signature_flow_form_logic_id_2'
              disabled={itemApplied}
            /> {I18n.t("signature_flow.form.logic_2")}
          </label>
        </div>

        {logicId() === 3 && <div className='form-group'>
          <label style={{ display: 'inline' }}>
            <input
              style={{ cursor: 'pointer' }}
              checked={logicId() === 3} onChange={logicHandler} type="radio" value="2" name="part_of_project" className='margin-right-add'
              id="projects_signature_flow_logic_id_3" disabled={itemApplied}
            /> {I18n.t("signature_flow.form.logic_3")}
          </label>
        </div>}

        {isWitnessStep(currentStepIndex) &&
          <h6>{I18n.t("signature_flow.form.witness_step_note")}</h6>
        }
      </div>
    </>
  )

}

export default LgcSfWitnessers;
