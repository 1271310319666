import React, { useContext } from 'react'
import { LgcSfContext } from '../../LgcSfContext';

import { isWitnessStep, totalSignersForStep } from '../../Utils/LgcSfHelperMethods';

const LgcSfNumberOfSignatures = ({ signaturesNumHandler, currentStep, logicId }) => {

  const { itemApplied } = useContext(LgcSfContext);
  const { currentStepIndex } = useContext(LgcSfContext);

  const maxSignatures = () => {
    const totalSigners = totalSignersForStep(currentStep.witnessers)
    const adjustedTotalSigners = totalSigners === 0 ? 1 : totalSigners;

    return (!isWitnessStep(currentStepIndex) && logicId() === 1) ? Math.min(3, adjustedTotalSigners) : 3;
  }

  return (
    <>
      {!isWitnessStep(currentStepIndex) &&
        <div className='form-group'>
          <label className='control-label' htmlFor="number-of-required-signatures">
            {I18n.t("signature_flow.form.number_of__required_signatures")}
          </label>
          <div className='row'>
            <div className='col-md-6'>
              <input
                data-lg-id={`signature_flow_form_required_signatures_size_${currentStepIndex}`}
                onChange={signaturesNumHandler}
                className='form-control'
                value={currentStep.num_signatures_required}
                title="(3 Max)"
                type="number"
                id="number-of-required-signatures"
                name="number-of-required-signatures"
                min='1'
                max={maxSignatures()}
                disabled={itemApplied}
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default LgcSfNumberOfSignatures;
