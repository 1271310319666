import React, { FC, HTMLAttributes } from 'react';

import { classMap } from '@common/utils';

interface LgcBadgeProps extends HTMLAttributes<HTMLDivElement> {
  lgId?: string;
  flag?: boolean;
  color?: string;
}

const LgcBadge: FC<LgcBadgeProps> = ({ lgId, children, className, color = 'white', flag, ...restProps }) => {
  return (
    <div
      data-lg-id={lgId}
      className={classMap({
        'status-badge': true,
        'status-badge--flag': !!flag,
        [`status-badge--${color}`]: true,
        [className ?? '']: true,
      })}
      {...restProps}
    >{children}</div>
  );
};

export default LgcBadge;
