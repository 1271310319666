import React, { ChangeEvent, FC } from 'react';

import { LgcFormField } from '@common/components';
import { AvantorCredentials } from './types';

interface AvantorCredentialsFormProps {
  disabled: boolean;
  credentials: AvantorCredentials;
  onChange: (credentials: AvantorCredentials) => void;
}

const AvantorCredentialsForm: FC<AvantorCredentialsFormProps> = ({ disabled, credentials, onChange }) => {
  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onChange({ ...credentials, [name]: value });
  };

  return (
    <form>
      <LgcFormField
        label="Email"
        name="email"
        type="email"
        disabled={disabled}
        value={credentials.email}
        onChange={handleFieldChange}
      />
      <LgcFormField
        label="Server URL"
        name="server_url"
        type="url"
        disabled={disabled}
        value={credentials.server_url}
        onChange={handleFieldChange}
      />
      <LgcFormField
        label="API key"
        name="xapikey"
        disabled={disabled}
        value={credentials.xapikey}
        onChange={handleFieldChange}
      />
      <LgcFormField
        label="Password"
        name="password"
        type="password"
        disabled={disabled}
        value={credentials.password}
        onChange={handleFieldChange}
      />
    </form>
  );
};

export default AvantorCredentialsForm;
