import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { setDisableButtons, setStep } from "./steps/stepperSlice";
import { updateEnzymes } from "./vectorSlice";
import { resetStettingsState } from "./settingsSlice";

const initialState = {
  status: 'pending',
  cloningObject: {},
  error: null,
  errorDetails: {}
}

export const cloneSlice = createSlice({
  name: 'clone',
  initialState,
  reducers: {
    cloneStart: (state) => {
      state.status = 'loading';
      state.error = null;
    },

    cloneSuccess: (state, action) => {
      state.status = 'completed';
      state.cloningObject = action.payload;
      state.error = null;
    },
    cloneFailure: (state, action) => {
      state.status = 'pending';
      state.error = true;
      state.errorDetails = action.payload;
    },
    updateCloningName: (state, action) => {
      state.cloningObject.name = action.payload;
    },
    updateCloningDescription: (state, action) => {
      state.cloningObject.description = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStettingsState, () => initialState);
  }

});

export const {
  cloneStart,
  cloneSuccess,
  cloneFailure,
  updateCloningName,
  updateCloningDescription,
} = cloneSlice.actions;

export const updateCloneThunk = (payload,callback) => async (dispatch) => {
  const id = payload.id;
  delete payload.id;
  const editUrl = `/api/v1/molecular_biology_cloning/${id}`;
  dispatch(cloneStart());
  dispatch(setDisableButtons(true));

  try {
    const response = await axios.put(editUrl, { item: payload }, { headers: {
        'Accept': 'application/json',
      }
    });
    dispatch(cloneSuccess(response.data));
    if(callback){
      callback(response.data);
    }
  } catch (error) {
    dispatch(cloneFailure(error.response.data.error_message));
    dispatch(setStep(1));
    Flash.error(error.response.data.error_message);
  }finally {
    dispatch(setDisableButtons(false));
  }

};
export const createCloneThunk = (payload, callback) => async (dispatch) => {
  const cloningUrl = '/api/v1/molecular_biology_cloning';
  dispatch(cloneStart());
  dispatch(setDisableButtons(true));

  try {
    const response = await axios.post(cloningUrl, { item: payload }, { headers: {
        'Accept': 'application/json',
      }
    });
    dispatch(cloneSuccess({id: response.data.id, base_plasmid: response.data.base_plasmid, name: response.data.name}));
    dispatch(updateEnzymes(response.data.restriction_enzyme_names.map((enzyme) => JSON.parse(enzyme))));
    if(callback){
      callback(response.data);
    }

  } catch (error) {
    dispatch(cloneFailure(error.response.data.error_message));
    dispatch(setStep(1));
    Flash.error(error.response.data.error_message);
  } finally{
    dispatch(setDisableButtons(false));
  }

}
export const selectCloningObject = (state) => state.clone.cloningObject;

export default cloneSlice.reducer;
