import React, { FC } from 'react';

import { PubNubProvider } from '@common/contexts';
import AvantorIntegrationSettings from './AvantorIntegrationSettings';
import { AvantorSyncStatus } from './types';

interface AppProps {
  status: AvantorSyncStatus;
}

const App: FC<AppProps> = ({ status }) => {
  return (
    <PubNubProvider>
      <AvantorIntegrationSettings status={status} />
    </PubNubProvider>
  );
};

export default App;
