import PubNub from 'pubnub';
import React, { FC, PropsWithChildren, createContext, useMemo } from 'react';

interface PubNubContextValue {
  pubnub: PubNub;
}

const PubNubContext = createContext<PubNubContextValue | undefined>(undefined);

export const PubNubProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useMemo(() => ({ pubnub: new PubNub(window.config.webNotificationsPubnubConf) }), []);

  return <PubNubContext.Provider value={value}>{children}</PubNubContext.Provider>;
};

export const usePubNub = () => {
  const context = React.useContext(PubNubContext);

  if (!context) {
    throw new Error('usePubNub must be used within a PubNubProvider');
  }
  return context;
};
