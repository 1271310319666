import React, { useContext, useState, useEffect } from 'react'
import { LgcSfContext } from '../LgcSfContext';

import { getNewSigFlow, updateSigFlow, createSigFlow } from '../Utils/LgcSfApiMethods'
import { cloneAndExtractWitnessers, isWitnessStep, displayFlashMessage } from '../Utils/LgcSfHelperMethods'
import { validateItem } from '../Utils/LgcSfFormValidations';

import LgcSfWitnessers from './Inputs/LgcSfWitnessers';
import LgcSfName from './Inputs/LgcSfName';
import LgcSfRole from './Inputs/LgcSfRole'
import LgcSfNumberOfSignatures from './Inputs/LgcSfNumberOfSignatures';
import LgcSfFormActions from './LgcSfFormActions';


const LgcSfForm = ({ handlePage, editFlowData, advanced, handleUpdateStep, currentStep, setItemValue, witnessersOptions, handleNewFlow, updateFlow }) => {

  const [readyToRender, setReadyToRender] = useState(false);
  const [basicItem, setBasicItem] = useState({})

  const { signatureFlow } = useContext(LgcSfContext);
  const { formMode } = useContext(LgcSfContext);
  const { currentStepIndex } = useContext(LgcSfContext);
  const { errors, setErrors } = useContext(LgcSfContext);

  useEffect(() => {
    if (formMode === 'new') {
      initNewTemplate();
    } else if (formMode === 'edit') {
      initEditTemplate()
    }
  }, [signatureFlow]);

  const initNewTemplate = async () => {
    if (!advanced) {
      const { data } = await getNewSigFlow({ advanced: false });
      setBasicItem(data.item)
    };
    setReadyToRender(true)
  }

  const initEditTemplate = async () => {
    setReadyToRender(true)
  }

  const logicHandler = (e) => {
    const value = Number(e.target.value);
    if (isWitnessStep(currentStepIndex)) {
      setItemValue('logic_id', value)
    } else {
      handleUpdateStep('logic_id', value)
    };

  };

  const witnessersStepHandler = (e) => {
    handleUpdateStep('witnessers', e);
  }

  const roleHandler = (e) => handleUpdateStep('role_of_signers', e.value);
  const signaturesNumHandler = (e) => handleUpdateStep('num_signatures_required', Number(e.target.value));


  const submitFlowForm = async (e) => {
    e.preventDefault();
    const itemToValidate = signatureFlow
    const validationErrors = validateItem(itemToValidate, advanced);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return; // Exit the function to prevent form submission
    }

    const clonedItem = cloneAndExtractWitnessers(signatureFlow, advanced);

    try {
      if (!clonedItem.id) {
        const res = await createSigFlow(clonedItem);
        if (res.status === 200) {
          displayFlashMessage('success', I18n.t("signature_flow.successfully_action", { action: 'created' }));
          handleNewFlow(res.data)
          handlePage('index')
        } else {
          displayFlashMessage('error', I18n.t("signature_flow.failed_action", { action: 'create' }));
        }
      } else {
        const res = await updateSigFlow(clonedItem);
        if (res.status === 200) {
          displayFlashMessage('success', I18n.t("signature_flow.successfully_action", { action: 'updated' }));
          updateFlow(res.data)
          handlePage('index')
        } else {
          displayFlashMessage('error', I18n.t("signature_flow.failed_action", { action: 'update' }));
        }
      }
    } catch (error) {
      console.log(error)
      displayFlashMessage('error', I18n.t("signature_flow.general_error"));
    }
  };

  const handleCreateOption = (newOption) => {
    roleHandler({ value: newOption })
    setRoles([...roles, newOption])
  }

  const logicId = () => {
    return isWitnessStep(currentStepIndex) ? signatureFlow.logic_id : currentStep.logic_id
  };

  return (
    <>
      {readyToRender ? (
        <>
          <>
            <form className='lgc-signature-flow-content-form' onSubmit={submitFlowForm}>
              <LgcSfName
                editFlowData={editFlowData}
                currentStep={currentStep}
                advanced={advanced}
                basicItem={basicItem}
                setItemValue={setItemValue}
                setBasicItem={setBasicItem}
              />

              <div className='rounded-section'>
                <div className='row'>
                  <div className='col-md-6'>
                    <LgcSfRole
                      currentStep={currentStep}
                      roleHandler={roleHandler}
                      handleCreateOption={handleCreateOption}
                      advanced={advanced}
                    />
                    <LgcSfWitnessers
                      options={witnessersOptions}
                      witnessersStepHandler={witnessersStepHandler}
                      logicHandler={logicHandler}
                      editFlowData={editFlowData}
                      currentStep={currentStep}
                      setItemValue={setItemValue}
                      setBasicItem={setBasicItem}
                      logicId={logicId}
                      advanced={advanced}
                    />

                    <LgcSfNumberOfSignatures
                      advanced={advanced}
                      logicId={logicId}
                      signaturesNumHandler={signaturesNumHandler}
                      currentStep={currentStep}
                    />
                  </div>
                </div>

              </div>
              < LgcSfFormActions />
            </form>
          </>
        </>

      ) :
        <div>
          <i> {I18n.t("signature_flow.loading")}</i>
        </div>

      }
    </>

  )
}

export default LgcSfForm;
