export function validateItem(item, advanced) {

  const errors = {};
  const nameError = I18n.t("signature_flow.validations.name_presence");
  const witnessersSizeError = I18n.t("signature_flow.validations.witnessers_size");
  const numSignaturesRequiredMin = I18n.t("signature_flow.validations.num_signatures_required_min");

  if (!item.name) {
    errors.name = nameError
  };
  if (item.logic_id == 1) {
    if (item.witnessers === undefined || item.witnessers === null || item.witnessers.length === 0) {
      errors.steps = {};
      errors.steps[-1] = {witnessers: witnessersSizeError}
    }
  };

  if (advanced) {
    item.steps.forEach((step, index) => {
      const positionErrors = {};
      if (step.logic_id == 1) {
        if (step.witnessers.length === 0) {
          positionErrors.witnessers = witnessersSizeError
        };
      };

      if (!step.num_signatures_required || step.num_signatures_required < 1) {
        positionErrors.num_signatures_required =  numSignaturesRequiredMin
      };
      // Save errors under the position object within each step
      if (Object.keys(positionErrors).length > 0) {
        errors.steps = errors.steps || {};
        errors.steps[step.position] = positionErrors;
      }
    });
  };
  return errors;
};
