import React, { useCallback, useState } from 'react';

import { LgcButton, LgcConfirmModal } from '@common/components';
import { useMutation, useQuery } from '@common/hooks';
import * as api from './api';
import FlagFormModal from './FlagFormModal';
import FlagsTable from './FlagsTable';
import { Flag } from './types';

type ModalType = 'form' | 'delete' | 'disable' | null;

const App = () => {
  const [flags, setFlags] = useState<Flag[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedFlag, setSelectedFlag] = useState<Flag | null>(null);
  const [activeModal, setActiveModal] = useState<ModalType | null>(null);

  const getFlags = useQuery({ queryFn: api.getFlags, onSuccess: setFlags });

  const openModal = (modal: ModalType, flag: Flag) => {
    setActiveModal(modal);
    setSelectedFlag(flag);
  };

  const closeModal = useCallback(() => {
    setError(null);
    setActiveModal(null);
    setSelectedFlag(null);
  }, []);

  const toggleFlag = useMutation({
    mutationFn: api.toggleFlag,
    onError: setError,
    onSuccess: flag => {
      setFlags(flags => flags.map(f => (f.id === flag.id ? { ...f, active: !f.active } : f)));
      closeModal();
    },
  });

  const deleteFlag = useMutation({
    mutationFn: api.deleteFlag,
    onError: setError,
    onSuccess: flag => {
      setFlags(flags => flags.filter(f => f.id !== flag.id));
      closeModal();
    },
  });

  const handleFlagToggle = (flag: Flag) => {
    flag.active ? openModal('disable', flag) : toggleFlag.mutate(flag);
  };

  const handleFormModalSave = (flag: Flag) => {
    selectedFlag
      ? setFlags(flags => flags.map(f => (f.id === flag.id ? flag : f)))
      : setFlags(flags => [...flags, flag]);
    closeModal();
  };

  return (
    <>
      <div className="flex justify-content-between my-5">
        <h4>Custom Flags</h4>
        <LgcButton lgId='add-flag-button' onClick={() => setActiveModal('form')}>New Flag</LgcButton>
      </div>

      <FlagsTable
        flags={flags}
        loading={getFlags.isLoading}
        onEdit={flag => openModal('form', flag)}
        onDelete={flag => openModal('delete', flag)}
        onToggle={handleFlagToggle}
      />

      <FlagFormModal
        value={selectedFlag}
        open={activeModal === 'form'}
        mode={selectedFlag ? 'edit' : 'create'}
        onClose={closeModal}
        onSave={handleFormModalSave}
      />

      <LgcConfirmModal
        title="Disable flag"
        lgId='flag-disable-modal'
        confirmButtonText="Disable"
        error={error}
        open={activeModal === 'disable'}
        loading={toggleFlag.isLoading}
        onClose={closeModal}
        onErrorDismiss={() => setError(null)}
        onConfirm={() => selectedFlag && toggleFlag.mutate(selectedFlag)}
      >
        <p>
          Please note that if you change the flag status to inactive, it will be removed from the flag selection
          options.
        </p>
        <p className="mb-0">You can reactivate it later to restore it to the selection options.</p>
      </LgcConfirmModal>

      <LgcConfirmModal
        title="Delete flag"
        lgId='flag-delete-modal'
        confirmButtonText="Delete"
        confirmButtonColor="red"
        error={error}
        open={activeModal === 'delete'}
        loading={deleteFlag.isLoading}
        onClose={closeModal}
        onErrorDismiss={() => setError(null)}
        onConfirm={() => selectedFlag && deleteFlag.mutate(selectedFlag)}
      >
        <p className="mb-0">
          You are about to delete <b>{selectedFlag?.title}</b> flag
        </p>
        <p className="mb-0">Are you sure you want to proceed?</p>
      </LgcConfirmModal>
    </>
  );
};

export default App;
