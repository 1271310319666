import React from 'react';

const StepContent = ({ step, all_members }) => {

  // const getUserNameById = (id) => {
  //   const member = all_members.find((member) => member[0] === id);
  //   if (member) {
  //     const [, firstName, lastName] = member;
  //     return I18n.t("signature_flow.signatures_progress.signed_by", { firstName: firstName, lastName: lastName });
  //   }
  //   return I18n.t("signature_flow.signatures_progress.user_not_found");
  // };

  return (
    <>
      <div className='lgc-progress-steps__step-number'>
        {step.position + 1}
      </div>
      <>
        <div className='lgc-progress-steps__step-description'>
          <div>
          {I18n.t("signature_flow.form.step_name", { step_name: step.role })} {step.completedCount}/{step.itemCount}
          </div>
          <div>
          {step.completedCount === step.itemCount ? I18n.t("signature_flow.signatures_progress.completed") : I18n.t("signature_flow.signatures_progress.pending")}
          </div>
        </div>
      </>
    </>
  )
};

export default function LgcStepperStep({ id, modifier, title, disabled, currentStepId, onClick, contentComponent, step, all_members }) {
  const handleClick = () => {
    if (disabled || id == currentStepId) {
      console.log('disabled');
    } else {
      onClick(id)
    }
  }

  const disabledClass = (disabled) ? 'lgc-progress-steps__step--disabled' : '';

  return (
    <>
      <li
        className={`lgc-progress-steps__step lgc-progress-steps__step${modifier} ${disabledClass}`}
        onClick={handleClick}
        data-disabled={disabled}
      >
        <StepContent step={step} all_members={all_members} />
      </li>
    </>
  )
}
