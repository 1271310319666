import React from 'react'

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from
} from "@apollo/client";

import { onError } from '@apollo/client/link/error'
import ShowInfo from './ShowInfo';

const InfoTable = ({ item_id, item_type, query, info }) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors){
      graphQLErrors.map(({ message, location, path}) => {
        console.error(message);
      })
    } 
  })

  const link = from([
    errorLink,
    new HttpLink({ uri: lgGraphQLServer() + lgFrontendToken() })
  ])

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: link
  });

  return (
    <ApolloProvider client={client}>
      <ShowInfo itemId={item_id} itemType={item_type} query={query} info={info}/>
    </ApolloProvider>
  )
}

export default InfoTable
