import React, { useState, useRef } from "react"
import { useDispatch } from 'react-redux';
import { toggleFlip, entireFragmentRadioSet, updateFragmentPartition } from "../../slices/fragmentsSlice";
import LgcEditor, { getEditorProps } from "../../../editor/LgcEditor";



const LgcFragmentShow = ({currentFragment}) => {
  const divStyle = {
    backgroundColor: '#eee',
    minHeight: '22.5px',
    transition: 'border-color 0.3s ease',
    border: '2px solid transparent'
  };
  const divNoContent = {
    backgroundColor: '#eee',
    minHeight: '22.5px',
    border: "2px solid var(--danger-border-color-accent)",
    transition: 'border-color 0.3s ease'
  }
  const dispatch = useDispatch();
  const editorRef = useRef();
  const editorProps = getEditorProps();

  editorProps.onSelectionOrCaretChanged =  (e) => {
    updateFragment(e);
  }

  const updateFragment = (e) => {
    dispatch(updateFragmentPartition(e));
  }
  const handleError = (error) => {
    Flash.error(error);
  }

  const handlePartitionChange = ({start, end}) => {
    start = parseInt(start || -1);
    end = parseInt(end || -1);
    const scope = editorRef.current.editor.getState();
    const data = {
      ...scope,
      selectionLayer: {
        ...scope.selectionLayer,
      },
    };
    data.selectionLayer.start = start;
    data.selectionLayer.end = end;
    editorRef.current.editor.updateEditor(data);
    updateFragment({ selectionLayer:{start: start || null, end :end || null}});
  }

  const handleToggleFlip = () => {
    dispatch(toggleFlip(currentFragment.id));
  }


  const editorCallbacks = (editor, data) => {
    data.selectionLayer.start = currentFragment.seq_start_index || 0;
    data.selectionLayer.end = currentFragment.seq_end_index || 0;
    editor.updateEditor(data);
  }

  const handleFragmentChoiceRadio = (event) => {
    dispatch(entireFragmentRadioSet({ value: event.target.value}));
    handlePartitionChange({start: null, end: null})
  }

  return (
    <div className='lgc-clone-wizard-fragment-details'>
      {
        currentFragment &&
          <>
            <h3 className='lgc-clone-wizard-fragment-details__title sub-title'>Fragment {currentFragment.id}</h3>
            <div className="form-inline lgc-clone-wizard-fragment-details__form">
              <div className='inputs' style={{'display': 'flex'}}>
                <div className='form-group'>
                  <div className='radio-control'>
                    <label htmlFor='lgcCloneWizardFragmentDetailsPart' className='radio-control__label'>Choose a {I18n.t('molecular_biology_cloning.part')}</label>
                    <input checked={!currentFragment.entire_fragment} id='lgcCloneWizardFragmentDetailsPart' name='lgcCloneWizardFragmentDetails' type='radio' className='radio-control__input' value='0' onChange={handleFragmentChoiceRadio}></input><br/>
                    <div style={{'visibility': currentFragment.entire_fragment? 'hidden':'visible'}}>
                      <div className="start_container" style={{display: 'flex'}}>
                        <label className='radio-control__label'> Start {!!currentFragment.entire_fragment} </label>
                        <label style= {currentFragment.seq_start_index? divStyle : divNoContent}>{currentFragment.seq_start_index}</label>
                        {/* <input size= "5" type='number' id='part_start' readOnly={true} value={currentFragment.seq_start_index} onChange={(e) => {handlePartitionChange({start:e.target.value})}}/><br/> */}
                      </div>
                      <div className="end_container" style={{display: 'flex'}}>
                        <label className='radio-control__label'> End {!currentFragment.entire_fragment}</label>
                        <label style= {currentFragment.seq_end_index? divStyle : divNoContent}>{currentFragment.seq_end_index}</label>
                        {/* <input size= "5" type='number' id='part_end' readOnly={true} value= {currentFragment.seq_end_index} onChange={(e) => {handlePartitionChange({end: e.target.value})}}/> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='radio-control'>
                    <label data-lg-id="entire_fragment" htmlFor='lgcCloneWizardFragmentDetailsEntireFragment' className='radio-control__label'>Choose {I18n.t('molecular_biology_cloning.entire')}</label>
                    <input checked={!!currentFragment.entire_fragment} id='lgcCloneWizardFragmentDetailsEntireFragment' name='lgcCloneWizardFragmentDetails' type='radio' className='radio-control__input' value='1' onChange={handleFragmentChoiceRadio}></input>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='radio-control'>
                    <label data-lg-id="lgcCloneWizardFragmentDetailsFlip" htmlFor='lgcCloneWizardFragmentDetailsFlip' className='radio-control__label'>{I18n.t('molecular_biology_cloning.flip')}</label>
                    <input checked={!!currentFragment.reverse} id='lgcCloneWizardFragmentDetailsFlip' name='lgcCloneWizardFragmentDetails' type='checkbox' className='radio-control__input' onChange={handleToggleFlip}></input>
                  </div>
                </div>
              </div>
              <LgcEditor
                url={`${window.location.origin}/api/v1/molecular_biology_fragments/${currentFragment.id}/sequence_data`}
                editorCallbacks={editorCallbacks}
                editorProps={editorProps}
                editorRef={editorRef}
                handleError={handleError}
               />
            </div>
          </>
      }
    </div>

  )
}
export default LgcFragmentShow;
