import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { enableResultsStep } from './resultsSlice'
import { setDisableButtons, setStep } from "./steps/stepperSlice";
import {disableVectorStep,enableVectorStep} from './vectorSlice';
import {disableSettingStep,enableSettingStep, resetStettingsState} from './settingsSlice';
import {disableFragmentsStep,enableFragmentsStep} from './fragmentsSlice';

const initialState = {
  status: 'pending',
  performCloneResponse: {},
  error: null,
  errorDetails: {},
  disabled: true
}

export const assembleSlice = createSlice({
  name: 'assemble',
  initialState,
  reducers: {
    performCloneStart: (state) => {
      state.status = 'loading';
      state.error = null;
    },
    performCloneSuccess: (state, action) => {
      state.status = 'completed';
      state.performCloneResponse = action.payload;
      state.error = null;
    },
    performCloneFailure: (state, action) => {
      state.status = 'pending';
      console.log("error payload", action.payload);
      state.error = true;
      state.errorDetails = action.payload;
    },
    disableAssemblyStep: (state) => {
      state.disabled = true
    },
    enableAssemblyStep: (state) => {
      state.disabled = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStettingsState, () => initialState);
  }

});

export const {
  performCloneStart,
  performCloneSuccess,
  performCloneFailure,
  disableAssemblyStep,
  enableAssemblyStep
} = assembleSlice.actions;

const performCloneUrl = '/api/v1/molecular_biology_cloning';
export const performCloneThunk = (payload) => async (dispatch) => {
  const id = payload.id;
  delete payload.id;
  dispatch(performCloneStart());
  dispatch(setDisableButtons(true))
  dispatch(disableAssemblyStep());
  dispatch(disableVectorStep());
  dispatch(disableSettingStep());
  dispatch(disableFragmentsStep());
  try {
    console.log({payload});
    const response = await axios.post(`${performCloneUrl}/${id}/perform_clone`, payload, { headers: {
        'Accept': 'application/json',
      },
    });
    if(response.data.assembled){
      dispatch(performCloneSuccess());
      dispatch(enableAssemblyStep());
      dispatch(enableVectorStep());
      dispatch(enableSettingStep());
      dispatch(enableFragmentsStep());
      dispatch(setStep(5));

    }else{
      dispatch(performCloneFailure(response.data.message));
      dispatch(enableResultsStep());
      dispatch(setStep(4));
      Flash.error(response.data.message);

    }
  } catch (error) {
    dispatch(performCloneFailure(error));
    dispatch(enableResultsStep());
    dispatch(setStep(4));
    Flash.error(error);

  } finally {
    dispatch(setDisableButtons(false));
  }
}

export default assembleSlice.reducer;
