import React, { FC } from 'react';
import LgcButton, { LgcButtonColor } from './LgcButton';
import LgcModal, { LgcModalProps } from './LgcModal';

interface LgcConfirmModalProps extends Omit<LgcModalProps, 'buttons'> {
  loading?: boolean;
  confirmButtonText?: string;
  confirmButtonColor?: LgcButtonColor;
  cancelButtonText?: string;
  cancelButtonColor?: LgcButtonColor;
  onClose: () => void;
  onConfirm: () => void;
}

const LgcConfirmModal: FC<LgcConfirmModalProps> = ({
  loading,
  children,
  size = 'sm',
  lgId = 'confirm-modal',
  confirmButtonText = 'confirm',
  confirmButtonColor = 'purple',
  cancelButtonText = 'cancel',
  cancelButtonColor = 'white',
  onClose,
  onConfirm,
  ...restProps
}) => {
  return (
    <LgcModal
      {...restProps}
      size={size}
      lgId={lgId}
      buttons={[
        <LgcButton small key="cancel" lgId={`${lgId}-cancel-button`} className="ms-auto" color={cancelButtonColor} onClick={onClose}>
          {cancelButtonText}
        </LgcButton>,
        <LgcButton
          small
          key="confirm"
          lgId={`${lgId}-confirm-button`}
          color={confirmButtonColor}
          disabled={loading}
          loading={loading}
          onClick={onConfirm}
        >
          {confirmButtonText}
        </LgcButton>,
      ]}
    >
      {children}
    </LgcModal>
  );
};

export default LgcConfirmModal;
