import { configureStore } from '@reduxjs/toolkit';

import portalReducer, { setContainerId } from '../slices/portalSlice';
import toastsReducer, { enqueue, prepend, replace, dismissAll } from '../slices/toastsSlice';

const store = configureStore({
  reducer: {
    portal: portalReducer,
    toasts: toastsReducer
  },
});

export default store;


// connect to LgFlash middleware:
const getQueue = () => {
  return store.getState().toasts;
}

const getContainerId = () => {
  return store.getState().portal.containerId;
}

const dispatchEnqueue = (newToast) => {
  store.dispatch(enqueue(newToast));
}

const dispatchReplace = (newToast) => {
  store.dispatch(replace(newToast));
}

const dispatchPrepend = (newToast) => {
  store.dispatch(prepend(newToast));
}

const dispatchDismissAll = () => {
  store.dispatch(dismissAll());
}

const dispatchSetContainerId = (id) => {
  store.dispatch(setContainerId(id))
}

window.LgFlash.connectWidget({
  getQueue,
  getContainerId,
  enqueue: dispatchEnqueue,
  prepend: dispatchPrepend,
  replace: dispatchReplace,
  dismissAll: dispatchDismissAll,
  setContainerId: dispatchSetContainerId
});
