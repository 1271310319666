import React, { createContext, useState } from 'react';

export const LgcSfContext = createContext();

export const LgcSfContextProvider = ({ children }) => {
  const [itemApplied, setItemApplied] = useState(false);
  const [signatureFlow, setSignatureFlow] = useState({});
  const [formMode, setFormMode] = useState('new');
  const [currentStepIndex, setCurrentStepIndex] = useState(-1);
  const [errors, setErrors] = useState({});
  const [page, setPage] = useState("index");

  return (
    <LgcSfContext.Provider value={{
      itemApplied, setItemApplied,
      signatureFlow, setSignatureFlow,
      formMode, setFormMode,
      currentStepIndex, setCurrentStepIndex,
      errors, setErrors,
      page, setPage,
      }}>
      {children}
    </LgcSfContext.Provider>
  );
};

