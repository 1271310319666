import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import Sentry from './sentry';

const application = Application.start();
window.Sentry = Sentry;

// Configure Stimulus development experience
application.debug = false;
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
window.Stimulus = application;
